import { AvailableFilters, FilterType } from "components/common/filters/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { Pagination } from "api/types";
import { PropsWithChildren, createContext, useContext, useState } from "react";
import { useQuery } from "hooks";

interface FilterContext<FF, T> {
  filters: AvailableFilters<FF, T>[];
  appliedFilters: AvailableFilters<FF, T>[];
  isFilterDrawerOpen: boolean;
  toggleFilterDrawer: () => void;
  closeFilterDrawer: () => void;
}

const context = createContext<FilterContext<any, any>>(undefined as any);

export const useFilterContext = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<any>> | ApiMiddlewareResult<Pagination<any>>,
  T extends string
>() => {
  const tabContext = useContext<FilterContext<FF, T>>(context);
  return tabContext;
};

export const withFilters = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<any>> | ApiMiddlewareResult<Pagination<any>>,
  T extends string,
  P
>(
  filters: AvailableFilters<FF, T>[] | (() => AvailableFilters<FF, T>[]),
  Component: React.ComponentType<P>,
) => {
  return (props: P) => (
    <ContextProvider filters={typeof filters === "function" ? filters() : filters}>
      {/* @ts-ignore */}
      <Component {...props} />
    </ContextProvider>
  );
};

const ContextProvider = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<any>> | ApiMiddlewareResult<Pagination<any>>,
  T extends string
>({
  filters,
  children,
}: PropsWithChildren<{ filters: AvailableFilters<FF, T>[] }>) => {
  const { query } = useQuery();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(prev => !prev);
  };
  const closeFilterDrawer = () => {
    setIsFilterDrawerOpen(false);
  };
  const appliedFilters = (() => {
    const appliedFilters = Object.keys(query);

    return filters
      .filter(filter => {
        if (Array.isArray(filter.name)) {
          return appliedFilters.some(name => name === filter.name[0] || name === filter.name[1]);
        }
        return appliedFilters.includes(filter.name);
      })
      .reverse();
  })();

  return (
    <context.Provider
      value={{ filters, isFilterDrawerOpen, toggleFilterDrawer, closeFilterDrawer, appliedFilters }}
    >
      {children}
    </context.Provider>
  );
};
export const filterFactory = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<any>> | ApiMiddlewareResult<Pagination<any>>,
  T extends string
>(
  arg: (utils: { FilterType: typeof FilterType }) => AvailableFilters<FF, T>[],
) => {
  return arg({ FilterType: FilterType });
};
