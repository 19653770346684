import {
  ImplementedBy,
  ManufacturingGroup,
  ManufacturingUnit,
  ManufacturingUnitGroupPriority,
} from "api/manufacturing/units/models";
import styles from "../../Panel.module.css";
import { cx, dateFns, getStandardDateFormat } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiBottomPanelClose } from "components/miloDesignSystem/atoms/icons/MdiBottomPanelClose";
import { useQuery, useSelector, useToggle } from "hooks";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiFlame } from "../../../../../../../../components/miloDesignSystem/atoms/icons/MdiFlame";
import { MdiSkull } from "components/miloDesignSystem/atoms/icons/MdiSkull";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useEffect, useState } from "react";
import { useGroupColumns } from "./useGroupColumns";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { InfoLabel } from "components/common/infoLabel";
import { ColumnType } from "../../../ColumnView";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { useParams } from "react-router-dom";
import { UUID } from "api/types";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MdiLowPriority } from "components/miloDesignSystem/atoms/icons/MdiLowPriority";
import { AttributeCategory } from "api/manufacturing/schemas/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { UserWithInitials } from "api/users/models";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { CancelManufacturingItem } from "pages/manufacturingNew/manufacturingStages/stageBoard/cancelManufacturingItem/CancelManufacturingItem";

interface Props {
  columnType: ColumnType | null;
  group?: ManufacturingGroup | null;
  unit?: ManufacturingUnit | null;
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>;
  setUnitDetails?: React.Dispatch<React.SetStateAction<ManufacturingUnit | null>>;
}

export const Todo = ({ columnType, group, unit, setGroupDetails, setUnitDetails }: Props) => {
  if (group) return <GroupTodo group={group} setGroupDetails={setGroupDetails} />;
  if (unit) return <UnitItem columnType={columnType} unit={unit} />;
  return null;
};

export const UnitItem = ({
  columnType,
  unit,
}: {
  columnType: ColumnType | null;
  unit: ManufacturingUnit;
}) => {
  const { updateQuery } = useQuery();
  const {
    data: unitDetails,
    error,
    isLoading,
  } = manufacturingUnitsActions.useGetTodoManufacturingUnit(unit.id);
  const priorityOptions = Object.entries(
    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict,
  ).map(([value, details]) => ({
    icon: details.dark.icon,
    text: details.dark.label,
    type: MenuItemType.ICON,
    value,
  }));
  const patchUnitPriority = manufacturingUnitsActions.useTodoManufacturingUnitPatch();
  const downloadUnitPdfLabel = manufacturingUnitsActions.useDownloadManufacturingItemPdf();
  const employees = useSelector(store => store.partials.employees);
  const employeesOptions = employees.map(employee => ({
    icon: employee.avatar,
    text: `${employee.firstName} ${employee.lastName}`,
    type: MenuItemType.ICON,
    value: employee.id,
  }));
  const cancelManufacturingItemModal = useToggle();

  if (error && columnType === "todo")
    return (
      <div className={cx(styles.panel, "pb-2")}>
        <CommonError status={error._httpStatus_} />
      </div>
    );

  if (isLoading && columnType === "todo")
    return (
      <div className={cx(styles.panel, "pb-2")}>
        <div className="d-flex align-items-center justify-content-center">
          <Spinner size={24} />
        </div>
      </div>
    );

  if (!unitDetails) return null;

  return (
    <>
      <div className={cx(styles.panel, "pb-2")}>
        <div
          className={cx(
            styles.ticketPanelHeader,
            "d-flex align-items-center justify-content-between gap-2 px-3 py-2",
            {
              [styles.groupPanelHeader]: columnType === ColumnType.READY,
              [styles.inProgressSingleUnitHeader]: columnType === ColumnType.TODO,
            },
          )}
        >
          <Typography
            color={
              manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[unit.priority].dark
                .color
            }
            fontSize="16"
            fontWeight="700"
            noWrap
          >
            {unitDetails.signature}
          </Typography>
          <div className="d-flex align-items-center justify-content-end gap-2">
            {columnType === ColumnType.TODO && (
              <Select
                items={priorityOptions}
                onChange={priority => {
                  patchUnitPriority.mutate({
                    id: unitDetails.id,
                    toUpdate: {
                      priority: priority as ManufacturingUnitGroupPriority,
                    },
                  });
                }}
                selected={unitDetails.priority}
                theme="dark"
              />
            )}
            <Tooltip title="Anuluj zlecenie">
              <IconButton
                icon={MdiCancel}
                onClick={cancelManufacturingItemModal.open}
                theme="dark"
                variant="transparent"
              />
            </Tooltip>
            <Tooltip title="Pobierz etykietę zlecenia">
              <IconButton
                icon={MdiQrCode}
                onClick={event => {
                  event.stopPropagation();
                  downloadUnitPdfLabel([unitDetails.manufacturingItem], unitDetails.signature);
                }}
                theme="dark"
                variant="transparent"
              />
            </Tooltip>
            <span className="line-divider" />
            <IconButton
              icon={MdiBottomPanelClose}
              onClick={() => updateQuery({ unitPanelId: "" })}
              theme="dark"
              variant="transparent"
            />
          </div>
        </div>
        <div className={cx(styles.todoPanelContent, "d-flex flex-column px-3 py-2 gap-2")}>
          <div className="w-100 nowrap">
            <Typography fontSize="16" fontWeight="700" noWrap>
              {unitDetails.name}
            </Typography>
            <div className="d-flex align-items-center gap-2 nowrap">
              {unitDetails.attributeValues?.map(attribute => (
                <Typography fontSize="14" fontWeight="600">
                  {attribute.value.name}
                </Typography>
              ))}
            </div>
          </div>
          <InfoLabel title="zamówienie">
            <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
              {unitDetails.orderSignature}
            </Typography>
          </InfoLabel>
          <InfoLabel title="zrealizować do">
            <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
              {unitDetails.scheduledAt
                ? getStandardDateFormat(unitDetails.scheduledAt)
                : EMPTY_VALUE}
            </Typography>
          </InfoLabel>
          {unitDetails.implementedBy === ImplementedBy.CONTRACTOR && unitDetails.manufacturer && (
            <InfoLabel title="realizacja">
              <Typography fontSize="14" fontWeight="700" noWrap>
                {unitDetails.manufacturer.name}
              </Typography>
            </InfoLabel>
          )}
          {columnType === ColumnType.READY &&
            unitDetails.implementedBy !== ImplementedBy.CONTRACTOR && (
              <InfoLabel title="pracownik">
                <Select.Async
                  items={employeesOptions}
                  mutationHook={manufacturingUnitsActions.useReadyManufacturingUnitPatch}
                  selected={unitDetails.employee?.id ?? null}
                  transformQueryData={employee => {
                    const searchedEmployee = employees.find(_employee => _employee.id === employee);
                    assertIsDefined(searchedEmployee);
                    return {
                      id: unitDetails.id,
                      toUpdate: {
                        employee: searchedEmployee as UserWithInitials,
                      },
                    };
                  }}
                />
              </InfoLabel>
            )}
          {columnType === ColumnType.READY && (
            <InfoLabel title="wykonano">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
                {unitDetails.finishedAt
                  ? dateFns.formatRelative(new Date(unitDetails.finishedAt))
                  : EMPTY_VALUE}
              </Typography>
            </InfoLabel>
          )}
          {unitDetails.isDeclined && (
            <div className="d-flex flex-column gap-2 py-2">
              <InfoLabel title="Do poprawy">
                <div />
              </InfoLabel>
              <div className={styles.declinedNote}>
                <Typography color="neutralBlack88" fontSize="14" fontWeight="500">
                  {unitDetails.note || EMPTY_VALUE}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
      {cancelManufacturingItemModal.isOpen && (
        <CancelManufacturingItem
          close={cancelManufacturingItemModal.close}
          id={unitDetails.manufacturingItemId}
          signature={unitDetails.signature}
        />
      )}
    </>
  );
};

const GroupTodo = ({
  group,
  setGroupDetails,
}: {
  group: ManufacturingGroup;
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>;
}) => {
  const { query, updateQuery } = useQuery();
  const [selectedGroups, setSelectedGroups] = useState<ManufacturingGroup["elements"]>([]);
  const columns = useGroupColumns(group.elements, selectedGroups, setSelectedGroups);
  const criticalPriorityMutation = manufacturingUnitsActions.useBulkPatchPriorityManufacturingUnits(
    setGroupDetails,
  );
  const highPriorityMutation = manufacturingUnitsActions.useBulkPatchPriorityManufacturingUnits(
    setGroupDetails,
  );
  const normalPriorityMutation = manufacturingUnitsActions.useBulkPatchPriorityManufacturingUnits(
    setGroupDetails,
  );
  const groupManufacturingUnitsMutation = manufacturingUnitsActions.useGroupManufacturingUnits(
    setGroupDetails,
  );
  const { stageId } = useParams<{ stageId: UUID }>();
  const downloadUnitPdfLabel = manufacturingUnitsActions.useDownloadManufacturingItemPdf();

  useEffect(() => {
    setSelectedGroups([]);
  }, [query.unitPanelId]);

  return (
    <div className={cx(styles.panel, "py-2")}>
      <div
        className={cx(
          styles.ticketPanelHeader,
          styles.groupPanelHeader,
          "d-flex align-items-center justify-content-between gap-2 px-3",
        )}
      >
        <div className={cx(styles.groupHeaderAttributes, "d-flex align-items-center gap-2")}>
          <Typography
            className={styles.groupHeaderSignature}
            color="neutralWhite100"
            fontSize="16"
            fontWeight="700"
            noWrap
          >
            {group.modelName ?? EMPTY_VALUE}
          </Typography>
          <div className="d-flex align-items-center gap-2 nowrap">
            {group.attributesValues?.map(attribute => (
              <Typography
                color="neutralWhite100"
                fontSize="14"
                fontWeight="600"
                key={`todoGroup-${attribute.attribute.id}`}
              >
                {attribute.value.name}
              </Typography>
            ))}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <Tooltip title="Pobierz etykiety grupy zleceń">
            <IconButton
              icon={MdiQrCode}
              onClick={event => {
                event.stopPropagation();
                downloadUnitPdfLabel(
                  group.elements.map(unit => unit.manufacturingItemId),
                  group.modelName,
                );
              }}
              theme="dark"
              variant="transparent"
            />
          </Tooltip>
          <span className="line-divider" />
          <IconButton
            icon={MdiBottomPanelClose}
            onClick={() => {
              updateQuery({ unitPanelId: "" });
              setSelectedGroups([]);
            }}
            theme="dark"
            variant="transparent"
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between gap-2 px-3 py-2">
        {Boolean(selectedGroups.length) && (
          <div className="d-flex align-items-center gap-1">
            <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
              wybrano:
            </Typography>
            <Typography color="neutralBlack88" fontSize="14" fontWeight="800">
              {selectedGroups.length}
            </Typography>
          </div>
        )}
        <div className="d-flex align-items-center w-100 justify-content-end gap-2">
          <Button
            className="text-uppercase"
            disabled={!Boolean(selectedGroups.length)}
            onClick={() =>
              groupManufacturingUnitsMutation.mutate(
                {
                  attributes: [
                    {
                      attribute: {
                        id: null,
                        name: "",
                      },
                      category: AttributeCategory.PRODUCT,
                      value: {
                        id: null,
                        name: group.modelName ?? "",
                      },
                    },
                    ...selectedGroups.flatMap(group =>
                      group.attributeValues.map(attr => ({
                        attribute: {
                          id: Number(attr.attribute.id),
                          name: attr.attribute.name,
                        },
                        category: attr.kind ?? null,
                        value: {
                          id: Number(attr.value.id),
                          name: attr.value.name,
                        },
                      })),
                    ),
                  ],
                  manufacturingWorkingUnitIds: selectedGroups.map(group => group.id),
                  schema_stage_id: stageId,
                },
                {
                  onSuccess: () => {
                    if (!Boolean(group.elements.length - selectedGroups.length)) {
                      updateQuery({ unitPanelId: "" });
                      setGroupDetails?.(null);
                    }
                    setSelectedGroups([]);
                  },
                },
              )
            }
            size="small"
            variant="gray"
          >
            Zgrupuj i przenieś do "w trakcie"
          </Button>
          <Tooltip title="Ustaw priorytet: 'Zwykły'">
            <IconButton
              disabled={!Boolean(selectedGroups.length)}
              icon={MdiLowPriority}
              isLoading={normalPriorityMutation.isLoading}
              onClick={() =>
                normalPriorityMutation.mutate({
                  ids: selectedGroups.map(group => group.id),
                  priority: ManufacturingUnitGroupPriority.C,
                })
              }
              variant="transparent"
            />
          </Tooltip>
          <Tooltip title="Ustaw priorytet: 'Pilny'">
            <IconButton
              disabled={!Boolean(selectedGroups.length)}
              icon={MdiFlame}
              isLoading={highPriorityMutation.isLoading}
              onClick={() =>
                highPriorityMutation.mutate({
                  ids: selectedGroups.map(group => group.id),
                  priority: ManufacturingUnitGroupPriority.B,
                })
              }
              variant="transparent"
            />
          </Tooltip>
          <Tooltip title="Ustaw priorytet: 'Krytyczny'">
            <IconButton
              disabled={!Boolean(selectedGroups.length)}
              icon={MdiSkull}
              isLoading={criticalPriorityMutation.isLoading}
              onClick={() =>
                criticalPriorityMutation.mutate({
                  ids: selectedGroups.map(group => group.id),
                  priority: ManufacturingUnitGroupPriority.A,
                })
              }
              variant="transparent"
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles.groupTableWrapper}>
        <Table
          columns={columns}
          rows={group.elements}
          isLoading={false}
          error={null}
          uiSchema={comfortableListUiSchema}
          overrides={() => {
            return {
              row: row => {
                return {
                  className: cx({
                    dashedTableRow: row.isCancelled,
                    [styles.skullUrgent]: row.priority === ManufacturingUnitGroupPriority.A,
                    [styles.flameUrgent]: row.priority === ManufacturingUnitGroupPriority.B,
                  }),
                };
              },
            };
          }}
        />
      </div>
    </div>
  );
};
