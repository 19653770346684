import { manufacturersActions } from "api/manufacturers/actions";
import { manufacturingActions } from "api/manufacturing/actions";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { useLatestOrdersColumns } from "./useLatestOrdersColumns";
import { ManufacturingOrder } from "api/manufacturing/models";
import { EmptySection } from "components/common/emptySection/EmptySection";
import { useNavigate } from "hooks/useNavigate";

export const LatestOrdersSection = () => {
  const { query } = useQuery();
  const { data: manufacturer } = manufacturersActions.useManufacturer(Number(query.panelId), {
    enabled: Boolean(query.panelId),
  });
  const search = queryString.stringify({
    manufacturer: manufacturer!.id,
    pageSize: 5,
  });
  const {
    data: orders,
    error,
    isLoading,
    isPreviousData,
  } = manufacturingActions.useGetManufacturingOrders(search);
  const navigate = useNavigate();

  const columns = useLatestOrdersColumns();
  const tableProps = {
    rows: orders,
  };

  return (
    <RightPanelSection
      title={
        <div className="w-100 d-flex align-items-center justify-content-between">
          <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
            Ostatnie zlecenia
          </Typography>
          <Button
            className="text-uppercase"
            endIcon={MdiArrowForward}
            onClick={() =>
              navigate(
                `/manufacturing/manufacturer-orders/list/all?manufacturer=${manufacturer!.id}`,
              )
            }
            size="medium"
            variant="gray"
          >
            Zobacz wszystkie
          </Button>
        </div>
      }
    >
      <Table<ManufacturingOrder>
        columns={columns}
        error={error}
        isLoading={isLoading || isPreviousData}
        uiSchema={comfortableListUiSchema}
        {...tableProps}
        overrides={() => ({
          hideHeader: !orders.length,
          noResultComponent: (
            <div className="mt-2">
              <EmptySection label="Brak zleceń" />
            </div>
          ),
        })}
      />
    </RightPanelSection>
  );
};
