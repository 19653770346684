import { QueryFetchError } from "api/types";
import { FormikHelpers } from "formik";

export const handleSubmitResponse = <T>(actions: FormikHelpers<T>) => {
  return {
    onSuccess: () => actions.setSubmitting(false),
    onError: (error: QueryFetchError) => {
      actions.setSubmitting(false);
      actions.setErrors(error.response?.data);
    },
  };
};
