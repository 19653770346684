import * as React from "react";
import deliveryConfirmedIcon from "assets/images/175.svg";
import sendSmsIcon from "assets/images/174.svg";
import { Row, Cell, Label, Checkbox } from "components/utils";
import { dateFns, getStandardDateFormat, noop } from "utilities";
import cx from "classnames";
import styles from "./ListRow.module.css";
import { OpacityCover } from "../disabledCover";
import { getLabelColor } from "./utils";
import editIcon from "assets/images/1.svg";
import dateIcon from "assets/images/4.svg";
import eyeIcon from "assets/images/170.svg";
import minusIcon from "assets/images/14c.svg";
import {
  ListOrder,
  Order,
  SimplOrderPaymentSource,
  SimpleListOrder,
  UpholsteryOrder,
} from "api/orders/models";
import { ManufacturerOrders } from "api/production-orders/models";
import { ProductionOrder } from "api/production-orders/models";
import { ListOrderGroup } from "api/order-groups/models";
import { Assign } from "utility-types";
import { UpholsteryStatuses } from "./upholsteryStatuses/UpholsteryStatuses";
import { ComplaintsStatusFlow } from "./complaintsStatusFlow";
import avatarImg from "assets/images/3.svg";
import { OrderStatusFlow } from "../orderStatusFlow";
import phoneIcon from "assets/images/122.svg";
import paperPlaneIcon from "assets/images/185w.svg";
import paidIcon from "assets/images/180.svg";
import phoneIcon2 from "assets/images/123.svg";
import { Manufacturer } from "api/manufacturers/models";
import { Car } from "api/cars/models";
import { OrderGroupStatus } from "./orderGroupStatus/OrderGroupStatus";
import { Button } from "../buttonLegacy";
import plusImg from "assets/images/20c.svg";
import icon1 from "assets/images/245.svg";
import icon2 from "assets/images/246.svg";
import icon3 from "assets/images/33.svg";
import { ProductionPlanList } from "api/production-plans/models";
import { Link } from "react-router-dom";
import { UpholsteryProductionListOrder } from "api/upholsteries/models";
import { useQuery, useSelector, useSettings } from "hooks";
import { FLAVOR } from "CONSTANTS";
import { CustomerSalesAccountLabelsSetting } from "api/milo-settings/models";
import { Mode } from "pages/orderGroups/OrderGroupsList";
import { ListCheckboxesState } from "hooks/useListCheckboxes";
import { Route } from "api/routes/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MoreDataCounter } from "../moreDataCounter";
import { Avatar as MdsAvatar } from "components/miloDesignSystem/atoms/avatar";
import { countryToFlagDict } from "constants/countriesFlags";
import { ordersUtils } from "utilities/orders";

interface RowProps {
  className: string;
  onClick: (arg: any) => void;
}

const paymentSourceDict: Record<SimplOrderPaymentSource, string> = {
  ON_DELIVERY: "gotówka",
  ONLINE: "przelew",
};

export const showSalesAccountLabel = (
  showLabels: CustomerSalesAccountLabelsSetting,
  customer: ListOrder["customer"] | Route["orders"][number]["customer"] | undefined,
  salesAccount: Order["salesAccount"],
): boolean => {
  return (
    salesAccount &&
    ([
      "ONLY_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST",
      "CUSTOMER_AND_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST",
      "CUSTOMER_LABELS_VISIBLE_IF_NOT_SALES_ACCOUNT_VISIBLE_IN_ORDER_LIST",
    ].includes(showLabels) ||
      (!customer &&
        showLabels === "SALES_ACCOUNT_LABELS_VISIBLE_IF_NOT_CUSTOMER_VISIBLE_IN_ORDER_LIST"))
  );
};

export const showCustomerLabel = (
  showLabels: CustomerSalesAccountLabelsSetting,
  customer: ListOrder["customer"] | Route["orders"][number]["customer"],
  salesAccount: Order["salesAccount"],
): boolean => {
  const result =
    customer &&
    ([
      "ONLY_CUSTOMER_LABELS_VISIBLE_IN_ORDER_LIST",
      "CUSTOMER_AND_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST",
      "SALES_ACCOUNT_LABELS_VISIBLE_IF_NOT_CUSTOMER_VISIBLE_IN_ORDER_LIST",
    ].includes(showLabels) ||
      (!salesAccount &&
        showLabels === "SALES_ACCOUNT_LABELS_VISIBLE_IF_NOT_CUSTOMER_VISIBLE_IN_ORDER_LIST"));
  if (result === null) {
    return false;
  }
  return result;
};

export const CheckMark = ({
  checked,
  className = "",
}: {
  checked: boolean;
  className?: string;
}) => (
  <Cell>
    <label className={cx(styles.readyMark, className)}>
      <input type="checkbox" disabled checked={checked} />
      <span className={styles.readyMarkCheckmark} />
    </label>
  </Cell>
);

const CellLabel = ({
  color,
  text,
}: {
  color:
    | "blue"
    | "orange"
    | "yellow-orange"
    | "red-orange"
    | "yellow"
    | "pink"
    | "grey"
    | "red"
    | "dark-red"
    | "purple"
    | "blueviolet"
    | "green"
    | "darkblue"
    | "darkcyan"
    | "midblue"
    | "cadetblue"
    | "burlywood"
    | "transparent"
    | "custom";
  text: string;
}) => (
  <Cell className={styles.timeCounter}>
    <Label color={color}>
      <span>{text}</span>
    </Label>
  </Cell>
);

const LeftDaysLabel = ({
  leftDays,
  type,
}: {
  leftDays: number;
  type?: "STANDARD" | "COMPLAINT";
}) => <CellLabel color={getLabelColor(leftDays, type || "STANDARD")} text={`${leftDays} dni`} />;

const Content = ({ top, bottom }: { top: React.ReactNode; bottom: React.ReactNode }) => (
  <div className={cx(styles.order, styles.orderDelivery)}>
    {top && <div className={styles.client}>{top}</div>}
    <div className={styles.address}>{bottom}</div>
  </div>
);
export const Address = ({
  name,
  clientLogin,
  street,
  postCode,
  city,
}: {
  name: React.ReactNode;
  street: string;
  clientLogin?: string;
  postCode: string;
  city: string;
}) => (
  <Content
    top={
      name || clientLogin ? (
        <span>
          {name} <em>{clientLogin ? <span>({clientLogin})</span> : null}</em>
        </span>
      ) : null
    }
    bottom={
      street || postCode || city ? (
        <span>
          {street}, {postCode} {city}
        </span>
      ) : null
    }
  />
);

const PrimaryHeading = ({
  order,
  showLabels = "CUSTOMER_AND_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST",
}: {
  order: {
    signature: string;
    salesAccount: Order["salesAccount"];
    created: string;
    isConfirmedInExternalService?: boolean;
    customer?: ListOrder["customer"];
  };
  showLabels?: CustomerSalesAccountLabelsSetting;
}) => (
  <div className={cx("d-flex", styles.order)}>
    <div className="d-flex">
      <div className={cx("mr-2", styles.signature)}>{order.signature}</div>
      {showSalesAccountLabel(showLabels, order.customer, order.salesAccount) && (
        <div
          className={styles.label}
          style={{
            backgroundColor: order.salesAccount.color,
          }}
        >
          <span style={{ color: order.salesAccount.textColor }}>{order.salesAccount.name}</span>
          {order.isConfirmedInExternalService && (
            <span
              title={`Zamówienie potwierdzone w ${order.salesAccount.channel}`}
              className={styles.externalServiceStatusDot}
            />
          )}
        </div>
      )}
    </div>
    <div className="d-flex align-items-center">
      <i className={cx(styles.divider)}></i>
      <div className={cx(styles.orderDate, styles.orderDateWeb)}>
        {dateFns.formatRelative(new Date(order.created))}
      </div>
      <div className={cx(styles.orderDate, styles.orderDateMobile)}>
        {dateFns.format(new Date(order.created), "d MMM yyyy")}
      </div>
    </div>
  </div>
);

export const CustomerOrderRow = ({
  onClick,
  className,
  order,
  highlighted,
  checkbox,
}: Assign<
  RowProps,
  {
    order: ListOrder;
    highlighted: boolean;
    checkbox: { show: boolean; checked: boolean; toggle: (order: ListOrder) => void };
  }
>) => {
  return (
    <Row
      onClick={onClick}
      className={cx(className, {
        [styles.notSeen]: highlighted,
      })}
    >
      <Cell>
        <div onClick={event => event.stopPropagation()}>
          <Checkbox
            checked={checkbox.checked}
            onChange={() => checkbox.toggle(order)}
            name=""
            overwrites={{ wrapper: { className: checkbox.show ? "" : "d-none" } }}
          />
        </div>
      </Cell>
      {checkbox.show === false && <CheckMark checked={order.isConfirmed} />}
      <Cell className={styles.orderDetails}>
        <div>
          <PrimaryHeading
            showLabels={"CUSTOMER_AND_SALES_ACCOUNT_LABELS_NOT_VISIBLE_IN_ORDER_LIST"}
            order={order}
          />
          <Address
            name=""
            clientLogin=""
            city={order.deliveryAddress.city}
            postCode={order.deliveryAddress.postCode}
            street={order.deliveryAddress.street}
          />
          {order.dateOfDelivered && (
            <div className="d-flex align-items-center mt-1">
              <span className="fs-14 text-color-grey">data dostarczenia </span>
              <i className={cx(styles.divider)} />
              <span className="fs-14 text-color-grey">
                {dateFns.format(new Date(order.dateOfDelivered), "dd.MM.yyyy HH:mm")}
              </span>
            </div>
          )}
        </div>
      </Cell>
      <Cell className="mr-3 d-flex align-items-start">
        <div className={cx(styles.invoiceContainer, "ml-2")}>
          <span className={styles.invoiceTitle}>meble</span>
          <div>
            <div className="d-flex align-items-center mb-2">
              <div
                className={cx(styles.iconLabel, order.hasItemsBeenInvoiced ? "bgBlue" : "bgPink")}
              >
                <img src={paperPlaneIcon} alt="" />
              </div>
              <span className={styles.invoiceTitle}>
                {order.itemsInvoicedDate && getStandardDateFormat(order.itemsInvoicedDate)}
              </span>
            </div>

            <div className="d-flex align-items-center">
              <div
                className={cx(styles.iconLabel, order.hasItemsBeenSettled ? "bgBlue" : "bgPink")}
              >
                <img src={paidIcon} alt="" />
              </div>
              <span className={styles.invoiceTitle}>
                {order.itemsSettledDate && getStandardDateFormat(order.itemsSettledDate)}
              </span>
            </div>
          </div>
        </div>
        <div className={cx(styles.invoiceContainer, "ml-2")}>
          <span className={styles.invoiceTitle}>transport</span>
          <div>
            <div className="d-flex align-items-center mb-2">
              <div
                className={cx(
                  styles.iconLabel,
                  order.hasTransportBeenInvoiced ? "bgBlue" : "bgPink",
                )}
              >
                <img src={paperPlaneIcon} alt="" />
              </div>
              <span className={styles.invoiceTitle}>
                {order.transportInvoicedDate && getStandardDateFormat(order.transportInvoicedDate)}
              </span>
            </div>

            <div className="d-flex align-items-center">
              <div
                className={cx(
                  styles.iconLabel,
                  order.hasTransportBeenSettled ? "bgBlue" : "bgPink",
                )}
              >
                <img src={paidIcon} alt="" />
              </div>
              <span className={styles.invoiceTitle}>
                {order.transportSettledDate &&
                  dateFns.format(new Date(order.transportSettledDate), "dd.MM.yyyy")}
              </span>
            </div>
          </div>
        </div>
      </Cell>
      <Cell className={styles.statuses}>
        <OrderStatusFlow isSeen={order.isSeen} status={order.status} />
        {order.status === "CANCELED" && (
          <Label className="ml-auto mr-2" color="primary">
            Anulowano
          </Label>
        )}
      </Cell>
      {!order.isSettled || order.status !== "CANCELED" ? (
        <LeftDaysLabel leftDays={order.leftDays} />
      ) : (
        <div className={styles.timeCounter}></div>
      )}
      <MdsAvatar size="sm" user={order.assignedTo} />
    </Row>
  );
};

export const RouteOrderRow = ({
  onClick,
  className,
  order,
  highlighted,
  checkbox,
}: Assign<
  RowProps,
  {
    order: SimpleListOrder;
    highlighted: boolean;
    checkbox?: { show: boolean; onChange: (order: ListOrder) => void; checked: boolean };
  }
>) => {
  const showLabels: CustomerSalesAccountLabelsSetting = useSelector(
    state => state.partials.configuration.customerSalesAccountLabelsInOrderList,
  );
  const showLabelsB2B: CustomerSalesAccountLabelsSetting = useSelector(
    state => state.partials.configuration.customerSalesAccountLabelsInOrderListB2b,
  );

  return (
    <Row
      onClick={onClick}
      className={cx(className, {
        [styles.notSeen]: highlighted,
      })}
    >
      <CheckMark checked={order.isConfirmed} className={cx({ "d-none": checkbox?.show })} />
      {checkbox && (
        <div onClick={event => event.stopPropagation()}>
          <Checkbox
            checked={checkbox.checked}
            onChange={() => checkbox.onChange(order)}
            name=""
            overwrites={{ wrapper: { className: checkbox.show ? "" : "d-none" } }}
          />
        </div>
      )}
      <Cell className={styles.orderDetails}>
        <div>
          <div className={cx("d-flex", styles.order)}>
            <div className="d-flex">
              <div className={cx("mr-2", styles.signature)}>{order.signature}</div>
              {FLAVOR === "main" &&
                showSalesAccountLabel(showLabels, order.customer, order.salesAccount) && (
                  <div
                    className={styles.label}
                    style={{
                      backgroundColor: order.salesAccount.color,
                    }}
                  >
                    <span style={{ color: order.salesAccount.textColor }}>
                      {order.salesAccount.name}
                    </span>
                    {order.isConfirmedInExternalService && (
                      <span
                        title={`Zamówienie potwierdzone w ${order.salesAccount.channel}`}
                        className={styles.externalServiceStatusDot}
                      />
                    )}
                  </div>
                )}
              {FLAVOR === "b2b" &&
                showSalesAccountLabel(showLabelsB2B, order.customer, order.salesAccount) && (
                  <div
                    className={styles.label}
                    style={{
                      backgroundColor: order.salesAccount.color,
                    }}
                  >
                    <span style={{ color: order.salesAccount.textColor }}>
                      {order.salesAccount.name}
                    </span>
                    {order.isConfirmedInExternalService && (
                      <span
                        title={`Zamówienie potwierdzone w ${order.salesAccount.channel}`}
                        className={styles.externalServiceStatusDot}
                      />
                    )}
                  </div>
                )}
              {FLAVOR === "main" &&
                order.customer &&
                showCustomerLabel(showLabels, order.customer, order.salesAccount) && (
                  <div
                    className={cx(styles.label, {
                      "ml-2": showSalesAccountLabel(showLabels, order.customer, order.salesAccount),
                    })}
                    style={{
                      backgroundColor: order.customer.color,
                    }}
                  >
                    <span style={{ color: order.customer.textColor }}>
                      {order.customer.name.toLowerCase()}
                    </span>
                  </div>
                )}
              {FLAVOR === "b2b" &&
                order.customer &&
                showCustomerLabel(showLabelsB2B, order.customer, order.salesAccount) && (
                  <div
                    className={cx(styles.label, {
                      "ml-2": showSalesAccountLabel(
                        showLabelsB2B,
                        order.customer,
                        order.salesAccount,
                      ),
                    })}
                    style={{
                      backgroundColor: order.customer.color,
                    }}
                  >
                    <span style={{ color: order.customer.textColor }}>
                      {order.customer.name.toLowerCase()}
                    </span>
                  </div>
                )}
            </div>
            <div className="d-flex align-items-center">
              <i className={cx(styles.divider)}></i>
              <div className={cx(styles.orderDate, styles.orderDateWeb)}>
                {dateFns.formatRelative(new Date(order.created))}
              </div>
              <div className={cx(styles.orderDate, styles.orderDateMobile)}>
                {dateFns.format(new Date(order.created), "d MMM yyyy")}
              </div>
            </div>
          </div>
          <Address
            name={
              order.customer && !order.client.hasDropShipping
                ? order.client.companyName
                : `${order.client.firstName} ${order.client.lastName}`
            }
            clientLogin={order.deliveryAddress.clientLogin}
            city={order.deliveryAddress.city}
            postCode={order.deliveryAddress.postCode}
            street={order.deliveryAddress.street}
          />
        </div>
      </Cell>
      <Cell className={cx(styles.receiptSection, "mr-2")}>
        <div>
          <small className="text-color-grey">Sposób płatności:</small>
          <div>{paymentSourceDict[order.paymentSource]}</div>
        </div>
      </Cell>
      <Cell className={cx(styles.receiptSection, "mr-2")}>
        <div>
          <small className="text-color-grey">Klient wybrał:</small>
          {Boolean(order.tradingDocuments.length) ? (
            <div>
              {order.tradingDocuments.some(tradingDocument => tradingDocument.canBeFiscalized)
                ? "Paragon"
                : "Faktura"}
            </div>
          ) : (
            <div>---</div>
          )}
        </div>
      </Cell>
      <Cell className={cx(styles.receiptSection, "mr-2")}>
        <div>
          <small className="text-color-grey">Nr dokumentu handlowego:</small>
          <div className="d-flex align-items-center">
            {Boolean(order.tradingDocuments.length) &&
              order.tradingDocuments.slice(0, 1).map(tradingDocument => {
                return tradingDocument ? tradingDocument.signature : "";
              })}{" "}
            {!Boolean(order.tradingDocuments.length) && <div>---</div>}
            &nbsp;
            {order.tradingDocuments.length - 1 > 0 ? (
              <div className="h-100 d-flex align-items-start">
                <Tag label={String(order.tradingDocuments.length - 1)} variant="info" />
              </div>
            ) : null}
          </div>
        </div>
      </Cell>

      <MdsAvatar size="sm" user={order.assignedTo} />
    </Row>
  );
};

export const OrderRow = ({
  onClick,
  className,
  order,
  isCovered,
  highlighted,
  checkbox,
}: Assign<
  RowProps,
  {
    isCovered: (id: number) => boolean;
    order: ListOrder;
    highlighted: boolean;
    checkbox?: { show: boolean; onChange: (order: ListOrder) => void; checked: boolean };
  }
>) => {
  const configuration = useSelector(state => state.partials.configuration);
  const salesSettings = useSelector(store => store.partials.salesSettings);
  const { query } = useQuery();
  const {
    sales: { listDisplay: settings },
  } = useSettings();
  const isManufacturingStatusValid =
    order.manufacturingStatus &&
    Boolean(
      order.manufacturingStatus.finished ||
        order.manufacturingStatus.requested ||
        order.manufacturingStatus.total,
    );

  return (
    <Row
      onClick={onClick}
      className={cx(className, {
        [styles.notSeen]: highlighted,
      })}
      data-testid="order"
    >
      <CheckMark checked={order.isConfirmed} className={cx({ "d-none": checkbox?.show })} />
      {checkbox && (
        <div onClick={event => event.stopPropagation()}>
          <Checkbox
            checked={checkbox.checked}
            onChange={() => checkbox.onChange(order)}
            name=""
            overwrites={{ wrapper: { className: checkbox.show ? "" : "d-none" } }}
          />
        </div>
      )}
      <Cell className={styles.orderDetails}>
        <div>
          <div className={cx("d-flex", styles.order)}>
            <div className="d-flex">
              <div className={cx("mr-2", styles.signature)}>{order.signature}</div>
              {FLAVOR === "main" &&
                showSalesAccountLabel(
                  configuration.customerSalesAccountLabelsInOrderList,
                  order.customer,
                  order.salesAccount,
                ) && (
                  <div
                    className={styles.label}
                    style={{
                      backgroundColor: order.salesAccount.color,
                    }}
                  >
                    <span style={{ color: order.salesAccount.textColor }}>
                      {order.salesAccount.name}
                    </span>
                    {order.isConfirmedInExternalService && (
                      <span
                        title={`Zamówienie potwierdzone w ${order.salesAccount.channel}`}
                        className={styles.externalServiceStatusDot}
                      />
                    )}
                  </div>
                )}
              {FLAVOR === "b2b" &&
                showSalesAccountLabel(
                  configuration.customerSalesAccountLabelsInOrderListB2b,
                  order.customer,
                  order.salesAccount,
                ) && (
                  <div
                    className={styles.label}
                    style={{
                      backgroundColor: order.salesAccount.color,
                    }}
                  >
                    <span style={{ color: order.salesAccount.textColor }}>
                      {order.salesAccount.name}
                    </span>
                    {order.isConfirmedInExternalService && (
                      <span
                        title={`Zamówienie potwierdzone w ${order.salesAccount.channel}`}
                        className={styles.externalServiceStatusDot}
                      />
                    )}
                  </div>
                )}
              {FLAVOR === "main" &&
                order.customer &&
                showCustomerLabel(
                  configuration.customerSalesAccountLabelsInOrderList,
                  order.customer,
                  order.salesAccount,
                ) && (
                  <div
                    className={cx(styles.label, {
                      "ml-2": showSalesAccountLabel(
                        configuration.customerSalesAccountLabelsInOrderList,
                        order.customer,
                        order.salesAccount,
                      ),
                    })}
                    style={{
                      backgroundColor: order.customer.color,
                    }}
                  >
                    <span style={{ color: order.customer.textColor }}>
                      {order.customer.name.toLowerCase()}
                    </span>
                  </div>
                )}
              {FLAVOR === "b2b" &&
                order.customer &&
                showCustomerLabel(
                  configuration.customerSalesAccountLabelsInOrderListB2b,
                  order.customer,
                  order.salesAccount,
                ) && (
                  <div
                    className={cx(styles.label, {
                      "ml-2": showSalesAccountLabel(
                        configuration.customerSalesAccountLabelsInOrderListB2b,
                        order.customer,
                        order.salesAccount,
                      ),
                    })}
                    style={{
                      backgroundColor: order.customer.color,
                    }}
                  >
                    <span style={{ color: order.customer.textColor }}>
                      {order.customer.name.toLowerCase()}
                    </span>
                  </div>
                )}
            </div>
            <div className="d-flex align-items-center">
              <i className={cx(styles.divider)} />
              <div className={cx(styles.orderDate, styles.orderDateWeb)}>
                {dateFns.formatRelative(new Date(order.placedAt))}
              </div>
              <div className={cx(styles.orderDate, styles.orderDateMobile)}>
                {dateFns.format(new Date(order.created), "d MMM yyyy")}
              </div>
            </div>
            {order.deliveryAddress.countryCode && (
              <div className="d-flex align-items-center ml-1 gap-1">
                <i className={cx(styles.divider)} />
                <div className="d-flex align-items-center gap-1">
                  <img
                    alt="Flaga kraju"
                    src={countryToFlagDict[order.deliveryAddress.countryCode]}
                  />
                  <div className="fs-12 fw-700">{order.deliveryAddress.countryCode}</div>
                </div>
              </div>
            )}
            {FLAVOR === "main" &&
              configuration.hasProductionOrdersService &&
              order.manufacturingStatus &&
              isManufacturingStatusValid && (
                <div className="d-flex align-items-center gap-2">
                  <i className={cx(styles.divider)} />
                  <div className="d-flex align-items-center gap-1">
                    <Tag
                      label={`zlecono produkcję ${order.manufacturingStatus.requested}/${order.manufacturingStatus.total}`}
                      type="outlined"
                      variant={
                        order.manufacturingStatus.requested === order.manufacturingStatus.total
                          ? "success"
                          : "info"
                      }
                    />
                    <Tag
                      label={`wyprodukowano ${order.manufacturingStatus.finished}/${order.manufacturingStatus.total}`}
                      type={order.manufacturingStatus.finished === 0 ? "outlined" : "filled"}
                      variant={
                        order.manufacturingStatus.finished === 0
                          ? "quaternary"
                          : order.manufacturingStatus.finished === order.manufacturingStatus.total
                          ? "success"
                          : "warning"
                      }
                    />
                  </div>
                </div>
              )}
            {Boolean(order.shipments.length) && (
              <div className="d-flex gap-2 align-items-center">
                {order.shipments.slice(0, 2).map((shipment, index) => (
                  <div className="d-flex align-items-center gap-1" key={index}>
                    <i className={cx(styles.divider)} />
                    <Tag
                      label={shipment.shippingService?.provider || `przesyłka ${index + 1}`}
                      variant="info"
                    />
                    {shipment.courierDeliveredDate ? (
                      <Tag label="dostarczono" variant="success" />
                    ) : (
                      <Tag label="nie dostarczono" variant="warning" />
                    )}
                  </div>
                ))}
                {order.shipments.length > 2 && (
                  <div className="position-relative ml-3">
                    <MoreDataCounter counter={order.shipments.length - 2} />
                  </div>
                )}
              </div>
            )}
          </div>
          {settings.name && <span className="fs-12">{order.name}</span>}
          <Address
            name={
              order.customer && !order.client.hasDropShipping
                ? order.client.companyName
                : `${order.client.firstName} ${order.client.lastName}`
            }
            clientLogin={order.deliveryAddress.clientLogin}
            city={order.deliveryAddress.city}
            postCode={order.deliveryAddress.postCode}
            street={order.deliveryAddress.street}
          />
        </div>
      </Cell>
      <div className="d-flex align-items-center mr-3 gap-1">
        {ordersUtils.smsStatusIcons[order.smsStatus].icon}
        {ordersUtils.customerConfirmationStatusIcons[order.customerConfirmationStatus].icon}
      </div>
      {FLAVOR === "main" && configuration.showOrderStatusOnOrderList && order.type === "STANDARD" && (
        <Cell className={styles.statuses}>
          <OrderStatusFlow isSeen={order.isSeen} status={order.status} />
        </Cell>
      )}
      {FLAVOR === "b2b" && order.status !== "CANCELED" && (
        <div className={query.panelId ? styles.containerWrapped : styles.smsContainer}>
          {configuration.showSmsSentOnOrderList && (
            <div
              className={cx(styles.smsSentContainer, "d-flex justify-content-start", {
                "pb-2 ": query.panelId,
              })}
            >
              {order.hasSmsSent ? (
                <div className={styles.smsContainer}>
                  <div className="d-flex flex-column">
                    <div className="d-flex mr-3">
                      <img alt="" src={sendSmsIcon} className="mr-2" />
                      <div>
                        <span className="text-color-blue nowrap fs-14">
                          Wysłano powiadomienie SMS
                        </span>
                        {order.smsSentTimestamp && (
                          <div className="fs-10 text-color-grey italic">
                            {dateFns.format(new Date(order.smsSentTimestamp), "dd.MM.yyyy, HH:mm")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.smsContainer}>
                  <div className="d-flex mr-3">
                    <img alt="" src={sendSmsIcon} className="mr-2" />
                    <span className="text-color-red nowrap fs-14">
                      Nie wysłano powiadomienia SMS
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          {configuration.showSmsDeliveryDateOnOrderList && (
            <div
              className={cx(styles.deliveryDateContainer, "d-flex ", {
                "pb-2 justify-content-start": query.panelId,
                "justify-content-end": !query.panelId,
              })}
            >
              {order.isDeliveryDateConfirm ? (
                <div
                  className={cx(
                    styles.deliveryDateConfirmed,
                    styles.confirmed,
                    "d-flex flex-column",
                  )}
                >
                  <div className="d-flex ">
                    <img alt="" src={deliveryConfirmedIcon} className="mr-2" />
                    <div className="d-flex flex-column">
                      <span className="text-color-darkblue nowrap fs-14">
                        Potwierdzono datę odbioru
                      </span>
                      {order.deliveryDateConfirmTimestamp && (
                        <div className="fs-10 text-color-darkblue italic">
                          {dateFns.format(
                            new Date(order.deliveryDateConfirmTimestamp),
                            "dd.MM.yyyy, HH:mm",
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={cx(styles.deliveryDateConfirmed, styles.notConfirmed)}>
                  <div className="d-flex ">
                    <img alt="" src={deliveryConfirmedIcon} className="mr-2" />
                    <span className="text-color-darkred nowrap fs-14">
                      Nie potwierdzono daty odbioru
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {FLAVOR === "b2b" && salesSettings?.showOrderStatusOnOrderList && order.type === "STANDARD" && (
        <Cell className={styles.statuses}>
          <OrderStatusFlow isSeen={order.isSeen} status={order.status} />
        </Cell>
      )}
      {order.isSettled &&
        (order.dateOfDelivered ? (
          <div className={styles.date}>
            {dateFns.format(new Date(order.dateOfDelivered), "d MMM yyyy")}
          </div>
        ) : (
          <div className={styles.date} />
        ))}
      {order.type === "COMPLAINT" && (
        <Cell>
          <ComplaintsStatusFlow kind={order.kind} warrantyStatus={order.warrantyStatus} />
        </Cell>
      )}
      {order.status !== "CANCELED" && order.type === "STANDARD" && !order.isSettled && (
        <LeftDaysLabel leftDays={order.leftDays} />
      )}
      {order.status !== "CANCELED" && order.type === "COMPLAINT" && !order.isSettled && (
        <LeftDaysLabel leftDays={order.numberOfDaysFromCreatedDate} type="COMPLAINT" />
      )}
      {order.type === "STANDARD" && (
        <Cell className={styles.contact}>
          <div
            title="Zapłacono"
            className={cx(styles.contactStatus, {
              [styles.paid]: order.paymentStatus === "PAID",
            })}
          >
            <img src={paidIcon} alt="" />
          </div>
          <div
            title="Wymagany telefon do klienta"
            className={cx(styles.contactStatus, { [styles.required]: order.isCallRequired })}
          >
            <img src={phoneIcon} alt="" />
          </div>
          <div
            title="Czekam na kontakt"
            className={cx(styles.contactStatus, {
              [styles.required]: order.isWaitingForCustomerCall,
            })}
          >
            <img src={phoneIcon2} alt="" />
          </div>
        </Cell>
      )}
      {FLAVOR === "main" && <MdsAvatar size="sm" user={order.assignedTo} />}
      <OpacityCover active={isCovered(order.id)} />
    </Row>
  );
};

export const UpholsteryRow = ({
  onClick,
  className,
  upholstery,
  checked,
  onCheck = noop,
}: Assign<
  RowProps,
  { upholstery: UpholsteryOrder; checked?: boolean; onCheck?: (status: boolean) => void }
>) => {
  const { client, deliveryAddress: address } = upholstery;
  return (
    <Row onClick={onClick} className={className}>
      {checked !== undefined && (
        <Cell onClick={e => e.stopPropagation()}>
          <Checkbox checked={checked} onChange={onCheck} name="" />
        </Cell>
      )}
      <Cell className={styles.orderDetails}>
        <div>
          <PrimaryHeading order={upholstery} />
          <Address
            name={client.companyName || `${client.firstName} ${client.lastName}`}
            clientLogin={address.clientLogin}
            city={address.city}
            postCode={address.postCode}
            street={address.street}
          />
        </div>
      </Cell>
      <UpholsteryStatuses upholstery={upholstery} />
    </Row>
  );
};

export const UpholsteryProductionOrderRow = ({
  onClick,
  className,
  upholstery,
  checkbox,
}: Assign<
  RowProps,
  {
    upholstery: UpholsteryProductionListOrder;
    checkbox?: { checked: boolean; toggle: (id: number) => void };
  }
>) => {
  const { name, upholsterer, preparedDate, isOrderCancelled } = upholstery;
  return (
    <Row
      onClick={onClick}
      className={cx(
        className,
        { [styles.materialSent]: upholstery.hasMaterialSent },
        { [styles.materialReceived]: upholstery.hasMaterialReceived },
      )}
    >
      {checkbox && (
        <div onClick={event => event.stopPropagation()}>
          <Checkbox
            checked={checkbox.checked}
            onChange={() => checkbox.toggle(upholstery.id)}
            name=""
          />
        </div>
      )}
      <Cell>
        <div>
          <div className="d-flex align-items-center">
            <div className={cx("mr-2", styles.signature)}>{name}</div>
            <div className="d-flex align-items-center gap-2">
              <div className="d-flex align-items-center gap-1">
                <img alt="Flaga kraju" src={countryToFlagDict[upholstery.countryCode]} />
                <div className="fs-12 fw-700">{upholstery.countryCode}</div>
              </div>
              <Label
                key={upholstery.id}
                color="custom"
                className="px-1 py-1 mr-2 text-color-black bgBlue d-inline-flex"
              >
                {upholstery.salesAccount}
              </Label>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className={cx("mr-2 text-color-grey", styles.signature)}>
              {upholstery.orderSignature}
            </div>
            {isOrderCancelled && <Label color="primary">Anulowano</Label>}
            <i className={cx(styles.divider, { "d-none": !Boolean(preparedDate) })}></i>
            <div className={styles.orderDate}>
              {preparedDate && dateFns.format(new Date(preparedDate), "d MMM yyyy, HH:mm")}
            </div>
          </div>
          {upholstery.attributes.map(attribute => (
            <Label
              key={attribute.attribute}
              color="custom"
              className="px-1 py-1 mr-2 mb-2 text-color-black bgLightGrey d-inline-flex"
            >
              {attribute.attribute}: {attribute.value}
            </Label>
          ))}
          <div className={styles.notes}>
            Uwagi: <span className="text-color-grey">{upholstery.note || "Brak uwag"}</span>
          </div>
        </div>
      </Cell>
      <Cell className={styles.plannedProductionDate}>
        <Label
          color={upholstery.plannedProductionDate ? "blue" : "grey"}
          className={styles.whiteImage}
        >
          <img src={dateIcon} alt="" />
        </Label>
        <div className={styles.orderDate}>
          {upholstery.plannedProductionDate &&
            getStandardDateFormat(upholstery.plannedProductionDate)}
        </div>
      </Cell>
      <Cell className={styles.iconContainer}>
        <Label color={upholstery.isPrepared ? "blue" : "grey"} className={styles.whiteImage}>
          <img src={icon3} alt="" />
        </Label>
        <Label color={upholstery.hasMaterialSent ? "blue" : "grey"} className={styles.whiteImage}>
          <img src={icon1} alt="" />
        </Label>
        <Label
          color={upholstery.hasMaterialReceived ? "blue" : "grey"}
          className={styles.whiteImage}
        >
          <img src={icon2} alt="" />
        </Label>
      </Cell>
      <Cell className="ml-4">
        {!upholsterer ? (
          <div className={cx(styles.avatar, className)}>
            <img src={avatarImg} alt="Zdjęcie użytkownika" />
          </div>
        ) : (
          <div className={cx(styles.avatar)} style={{ background: "#00CF91" }}>
            <span>{upholsterer.firstName.charAt(0).toLocaleUpperCase()}</span>
            <span>{upholsterer.lastName.charAt(0).toLocaleUpperCase()}</span>
          </div>
        )}
      </Cell>
    </Row>
  );
};

export const ShipmentRow = ({
  onClick,
  className,
  order,
  isCovered,
  highlighted,
}: Assign<
  RowProps,
  { isCovered: (id: number) => boolean; order: ListOrder; highlighted: boolean }
>) => {
  const { client, deliveryAddress: address } = order;
  return (
    <Row
      onClick={onClick}
      className={cx(className, {
        [styles.notSeen]: highlighted,
      })}
    >
      <CheckMark checked={order.isShipped} />
      <Cell className={styles.orderDetails}>
        <div>
          <div className={cx("mr-2", styles.signature)}>{order.signature}</div>
          <Address
            name={
              order.customer !== null
                ? client.companyName
                : `${client.firstName} ${client.lastName}`
            }
            clientLogin={address.clientLogin}
            city={address.city}
            postCode={address.postCode}
            street={address.street}
          />
        </div>
      </Cell>
      <Cell>
        {order.isShipped ? (
          <Label color="grey" className="mr-3">
            Zlecono
          </Label>
        ) : null}
      </Cell>
      <MdsAvatar size="sm" user={order.assignedTo} />
      <OpacityCover active={isCovered(order.id)} />
    </Row>
  );
};

export const ManufacturerRow = ({
  onClick,
  className,
  manufacturer,
  isCovered,
}: Assign<RowProps, { manufacturer: Manufacturer; isCovered: boolean }>) => {
  return (
    <Row onClick={onClick} className={cx(className)}>
      <Cell className={styles.orderDetails}>
        <div>
          <div>{manufacturer.fullName}</div>
          <Address
            name={""}
            clientLogin={""}
            city={manufacturer.city}
            postCode={manufacturer.postCode}
            street={manufacturer.street}
          />
        </div>
      </Cell>
      <OpacityCover active={isCovered} />
    </Row>
  );
};

export const CarRow = ({
  onClick,
  className,
  car,
  isCovered,
}: Assign<RowProps, { car: Car; isCovered: boolean }>) => {
  return (
    <Row onClick={onClick} className={cx(className)}>
      <Cell className={styles.orderDetails}>
        <Content
          top={<strong>{car.brand}</strong>}
          bottom={
            <span className="d-flex align-items-center">
              {car.registrationNumber} <div className="divider" /> <em>{car.description}</em>
            </span>
          }
        />
      </Cell>
      <OpacityCover active={isCovered} />
    </Row>
  );
};

export const OrderGroupRow = ({
  onClick,
  className,
  orderGroup,
  isCovered,
  mode,
  checkboxes,
}: Assign<
  RowProps,
  {
    orderGroup: ListOrderGroup;
    isCovered: boolean;
    mode: Mode;
    checkboxes: ListCheckboxesState<ListOrderGroup>;
  }
>) => {
  /**
   * Removes seconds from time format
   * 22:00:00 -> 22:00
   */
  function formatTime(time: string | null) {
    if (!time) return "";
    const splitted = time.split(":");
    return `${splitted[0]}:${splitted[1]}`;
  }

  return (
    <Row onClick={onClick} className={cx(className)}>
      {mode !== "default" && (
        <div onClick={event => event.stopPropagation()}>
          <Checkbox
            checked={checkboxes.check(orderGroup.id)}
            name=""
            onChange={() => checkboxes.toggle(orderGroup)}
          />
        </div>
      )}
      <CheckMark checked={orderGroup.isDraft === false} />
      <Cell className={styles.orderDetails}>
        <Content
          top={
            <strong className={styles.routeSignature}>
              {orderGroup.signature}
              <span className={cx("ml-1", styles.orderGroupName)}>{orderGroup.name}</span>
            </strong>
          }
          bottom={
            <span className={"d-flex align-items-center"}>
              <span>
                {orderGroup.pickUpTime ? (
                  <span className={cx("mr-1", styles.orderGroupDate)}>
                    {formatTime(orderGroup.pickUpTime)}{" "}
                  </span>
                ) : (
                  <span className={styles.noTime}>dodaj godzinę odbioru |</span>
                )}
              </span>
              <span>
                {orderGroup.pickUpDate ? (
                  <span className={cx(styles.orderGroupDate)}>
                    {" "}
                    {dateFns.format(new Date(orderGroup.pickUpDate), "d MMM yyyy")}
                  </span>
                ) : (
                  <span className={styles.noData}> dodaj datę odbioru</span>
                )}
              </span>
            </span>
          }
        />
      </Cell>
      <Cell>
        <OrderGroupStatus status={orderGroup.status} />
        <div className="mr-3" />
        <MdsAvatar size="sm" user={orderGroup.createdBy} />
      </Cell>
      <OpacityCover active={isCovered} />
    </Row>
  );
};

export const OrderGroupOrderRow = ({
  onClick,
  className,
  order,
  isAdded,
  toggleAdd,
  highlighted,
  btnDisabled,
  isCanceled,
}: Assign<
  RowProps,
  {
    isAdded: boolean;
    order: ListOrder;
    highlighted: boolean;
    toggleAdd: () => void;
    btnDisabled: boolean;
    isCanceled: boolean;
  }
>) => {
  const { client, deliveryAddress: address } = order;
  return (
    <Row
      onClick={onClick}
      className={cx(className, {
        [styles.notSeen]: highlighted,
      })}
    >
      <CheckMark checked={order.isConfirmed} />
      <Cell className={styles.orderDetails}>
        <div>
          <div className={cx("mr-2", styles.signature)}>{order.signature}</div>
          <Address
            name={
              order.customer !== null
                ? client.companyName
                : `${client.firstName} ${client.lastName}`
            }
            clientLogin={""}
            city={address.city}
            postCode={address.postCode}
            street={address.street}
          />
        </div>
      </Cell>
      <Cell>
        <MdsAvatar size="sm" user={order.assignedTo} />
        <Button
          kind="secondary-stroke"
          size="small"
          onClick={e => {
            e.stopPropagation();
            toggleAdd();
          }}
          className="ml-3"
          disabled={btnDisabled}
        >
          <img alt="Dodaj/usuń grupę" src={isAdded ? minusIcon : plusImg} />
          {isAdded ? "Usuń" : "Dodaj"}
        </Button>
      </Cell>
      <OpacityCover active={isCanceled} />
    </Row>
  );
};

export const ManufacturersOrderRow = ({
  onClick,
  className,
  order,
  isCovered,
}: Assign<RowProps, { order: ManufacturerOrders; isCovered: boolean }>) => (
  <Row onClick={onClick} className={cx(className)}>
    <Cell className={styles.orderDetails}>
      <div>
        <div className="d-flex align-items-center">
          <div className={cx("mr-2", styles.signature)}>{order.signature}</div>
          <div
            className={cx(styles.label, "mx-1")}
            style={{
              backgroundColor: order.createdFor === "CLIENT" ? "#D4CD03" : "#01c9d5",
            }}
          >
            {order.createdFor === "CLIENT" ? "Klient" : "Magazyn"}
          </div>
          <i className={cx(styles.divider)} />
          <div className={styles.orderDate}>
            {dateFns.format(new Date(order.created), "d MMM yyyy")}
          </div>
        </div>
        <div>{order.manufacturer.name}</div>
      </div>
    </Cell>
    <Cell>
      <small className="d-flex mr-5 font-weight-bold">
        Zlecono <span className="mr-1 ml-2">{order.sentIndexesCount}</span> /
        <span className="ml-1">{order.indexesCount}</span>
      </small>
    </Cell>
    <Cell className={styles.labelContainer}>
      {order.isSentToManufacturer ? (
        <Label color="grey" className={styles.manufacturersOrderLabel}>
          Zlecono
        </Label>
      ) : (
        <Label color="primary" className={styles.manufacturersOrderLabel}>
          Zleć produkcję
        </Label>
      )}
    </Cell>
    <MdsAvatar size="sm" user={order.createdBy} />
    <OpacityCover active={isCovered} />
  </Row>
);

export const ProductionOrderRow = ({
  onClick,
  className,
  order,
  isCovered,
}: Assign<RowProps, { order: ProductionOrder; isCovered: boolean }>) => (
  <Row onClick={onClick} className={cx(className)}>
    <Cell className={styles.orderDetails}>
      <div>
        <div className="d-flex align-items-center">
          <div className={cx("mr-2", styles.signature)}>{order.signature}</div>
          {order.isOrderCancelled && <Label color="primary">Anulowano</Label>}
          <i className={cx(styles.divider)}></i>
          <div className={styles.orderDate}>
            {dateFns.format(new Date(order.created), "d MMM yyyy")}
          </div>
        </div>
      </div>
    </Cell>
    <MdsAvatar size="sm" user={order.createdBy} />
    <OpacityCover active={isCovered} />
  </Row>
);

export const ProductionPlanRow = ({ productionPlan }: { productionPlan: ProductionPlanList }) => (
  <Row>
    <Cell className={styles.orderDetails}>
      <div>
        <div className="d-flex align-items-center">
          <div className={cx("mr-2", styles.signature)}>{productionPlan.name}</div>
          <i className={cx(styles.divider)} />
          <div className={styles.orderDate}>
            {dateFns.format(new Date(productionPlan.created), "d MMM yyyy")}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <span className={cx("text-color-grey", styles.productionPlanLabel)}>Obowiązuje od</span>
            <span className={styles.productionPlanDate}>
              {getStandardDateFormat(productionPlan.dateFrom)}
            </span>
          </div>
          <div>
            <span className={cx("text-color-grey", styles.productionPlanLabel)}>do</span>
            <span className={styles.productionPlanDate}>
              {getStandardDateFormat(productionPlan.dateTo)}
            </span>
          </div>
        </div>
      </div>
    </Cell>
    <div className="mr-3">
      <MdsAvatar size="sm" user={productionPlan.createdBy} />
    </div>
    <Link
      className={cx(styles.appearBtn, "mr-2")}
      to={`/production-plans/details/${productionPlan.id}`}
    >
      <img src={eyeIcon} alt="" />
    </Link>
    <Link className={styles.appearBtn} to={`/production-plans/edit/${productionPlan.id}`}>
      <img src={editIcon} alt="" />
    </Link>
  </Row>
);

export const ListRow = {
  Order: OrderRow,
  RouteOrder: RouteOrderRow,
  CustomerOrder: CustomerOrderRow,
  Upholstery: UpholsteryRow,
  Shipment: ShipmentRow,
  Manufacturer: ManufacturerRow,
  Car: CarRow,
  OrderGroup: OrderGroupRow,
  OrderGroupOrder: OrderGroupOrderRow,
  ManufacturerOrders: ManufacturersOrderRow,
  ProductionOrders: ProductionOrderRow,
  ProductionPlan: ProductionPlanRow,
  UpholsteryProductionOrder: UpholsteryProductionOrderRow,
};
