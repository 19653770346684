import { useOrderListColumns } from "./useOrderListColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { EmptySection } from "components/common/emptySection/EmptySection";
import { manufacturingActions } from "api/manufacturing/actions";
import { useQuery } from "hooks";
import { MaterialOrderListItem } from "api/manufacturing/models";
import styles from "./OrderList.module.css";
import { useFilters } from "hooks/useFilters";

export const OrderList = () => {
  const { query } = useQuery();
  const { searchParams, setFilter } = useFilters({
    page: 1,
    materials: query.panelId,
  });
  const {
    data: orders,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingActions.useGetManufacturingMaterialOrders(searchParams);
  const columns = useOrderListColumns();

  return (
    <div className="pb-2">
      <Table<MaterialOrderListItem>
        rows={orders}
        columns={columns}
        isLoading={isLoading || isPreviousData}
        error={error}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
        uiSchema={{
          header: {
            backgroundColor: "neutralWhite100",
          },
          cell: {
            height: "24",
          },
        }}
        overrides={() => {
          return {
            row(row) {
              return { className: row.receivedAt ? "" : styles.notDeliveredRow };
            },
            hideHeader: !orders.length,
            noResultComponent: (
              <div className="mt-2">
                <EmptySection label="Brak zamówień" />
              </div>
            ),
          };
        }}
      />
    </div>
  );
};
