import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import cx from "classnames";
import React from "react";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  correctionInvoice: TradingDocument;
}

export const MasterDocumentSection = ({ correctionInvoice }: Props) => {
  return (
    <RightPanelSection>
      <InfoLabel title="do dokumentu">
        {correctionInvoice.masterDocument && (
          <Typography fontSize="16" fontWeight="800">
            {correctionInvoice.masterDocument.signature || "---"}
          </Typography>
        )}
      </InfoLabel>
      <InfoLabel title="z datą wystawienia">
        <Typography fontSize="16" fontWeight="700">
          {correctionInvoice.masterDocument?.invoiceIssueDate || "---"}
        </Typography>
      </InfoLabel>
      <InfoLabel title="z datą dostawy">
        <Typography fontSize="16" fontWeight="700">
          {correctionInvoice.masterDocument?.invoiceDeliveryDate || "---"}
        </Typography>
      </InfoLabel>
      <InfoLabel title="przyczyna">
        <div className={cx("body-14 fw-800")}>
          {correctionInvoice.reasons.length > 0
            ? correctionInvoice.reasons.map((reason, index) => (
                <React.Fragment key={index}>
                  {correctionInvoice.reasons.length > index + 1 ? (
                    <>
                      {tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action]}
                      ,&nbsp;
                    </>
                  ) : (
                    <>
                      {tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action]}
                    </>
                  )}
                </React.Fragment>
              ))
            : "--"}
        </div>
      </InfoLabel>
      <InfoLabel title="powód korekty">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz powód korekty"
          size="small"
          transformQueryData={correctionReason => ({
            id: correctionInvoice.id,
            correctionReason,
          })}
          value={correctionInvoice.correctionReason}
        />
      </InfoLabel>
      {/* TODO: Sign document section */}
    </RightPanelSection>
  );
};
