import { wmsReturnsActions } from "api/wms/returns/actions";
import { ReturnDetails, ReturnLineItem, ReturnStatus } from "api/wms/returns/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { useFilters } from "hooks/useFilters";
import styles from "../RightPanel.module.css";
import { cx } from "utilities";
import { usePackagesToReturnColumns } from "./usePackagesToReturnColumns";
import { EmptySection } from "components/common/emptySection/EmptySection";
import { useStateModal } from "hooks";
import { UUID } from "api/types";
import { PackageToReturnModal } from "./packageToReturnModal/PackageToReturnModal";

interface Props {
  returnDetails: ReturnDetails;
}

export const PackagesToReturn = ({ returnDetails }: Props) => {
  const { searchParams } = useFilters({ wmsReturn: returnDetails.id });
  const {
    data: packagesToReturn,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = wmsReturnsActions.useGetPackagesToReturn(searchParams);
  const columns = usePackagesToReturnColumns(returnDetails);
  const tableProps = useTableFeatureConnector({
    rows: packagesToReturn,
    withPagination: { pagination, defaultPaginationVisibility: false },
  });
  const lineItemDetailsModal = useStateModal<UUID>();

  return (
    <>
      <RightPanelSection title="Paczki do zwrotu">
        <div className="pb-3">
          <Table<ReturnLineItem>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
            {...tableProps}
            onRowClick={rowId => lineItemDetailsModal.open(String(rowId))}
            overrides={() => {
              return {
                hideHeader: !packagesToReturn?.length,
                noResultComponent: <EmptySection label="Brak paczek do zwrotu" />,
                ...tableProps.overrides?.(),
                row: row => {
                  return {
                    className: cx("cursor-pointer", {
                      [styles.finishedItem]: row.status === ReturnStatus.FINISHED,
                      [styles.inProgressItem]: row.status === ReturnStatus.IN_PROGRESS,
                      [styles.notFinishedItem]:
                        row.status !== ReturnStatus.FINISHED &&
                        returnDetails.status === ReturnStatus.FINISHED,
                    }),
                  };
                },
              };
            }}
          />
        </div>
      </RightPanelSection>
      {lineItemDetailsModal.isOpen && lineItemDetailsModal.state && (
        <PackageToReturnModal
          close={lineItemDetailsModal.close}
          lineItemId={lineItemDetailsModal.state}
        />
      )}
    </>
  );
};
