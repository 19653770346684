import styles from "./WarehouseSchemaToolbar.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TableToolbarWrapper } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiCheckIndeterminateSmall } from "components/miloDesignSystem/atoms/icons/MdiCheckIndeterminateSmall";
import { useRedux, useSelector } from "hooks";

export const WarehouseSchemaToolbar = () => {
  const zoom = useSelector(state => state.ui.warehouseSchemaZoom);
  const [dispatch, { ui }] = useRedux();
  return (
    <TableToolbarWrapper className={styles.toolbar}>
      <IconButton
        icon={MdiCheckIndeterminateSmall}
        variant="transparent"
        theme="dark"
        onClick={() => dispatch(ui.changeWarehouseSchemaZoom(zoom - 1))}
      />
      <Typography fontSize="14" fontWeight="600" color="neutralWhite100">
        {zoom}%
      </Typography>
      <IconButton
        icon={MdiAdd}
        variant="transparent"
        theme="dark"
        onClick={() => dispatch(ui.changeWarehouseSchemaZoom(zoom + 1))}
      />
    </TableToolbarWrapper>
  );
};
