import { materialsActions } from "api/manufacturing/materials/actions";
import { MaterialRemovalOption, MaterialRemovalPreview } from "api/manufacturing/materials/models";
import { UUID } from "api/types";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useFilters } from "hooks/useFilters";
import { useState } from "react";
import { pluralize } from "utilities";

interface Props {
  close: () => void;
  materialId: UUID;
  materialRemovalPreview: MaterialRemovalPreview | null;
}

interface Filters {
  isResetMaterialStockRequired: boolean;
  option: MaterialRemovalOption;
}

export const ConfirmMaterialRemoval = ({ close, materialId, materialRemovalPreview }: Props) => {
  const { filters, searchParams, setFilter } = useFilters<Filters>({
    isResetMaterialStockRequired: false,
    option: MaterialRemovalOption.DISABLE_IN_RECIPE,
  });
  const confirmMaterialRemoval = materialsActions.useConfirmMaterialRemoval(close);
  const [confirmRemoval, setConfirmRemoval] = useState(false);
  const hasAnyRecipies = materialRemovalPreview && materialRemovalPreview.numberOfRecipies > 0;

  if (!materialRemovalPreview) return null;

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Usuń materiał
        </Typography>
      }
      width={480}
    >
      <div className="px-3">
        <div className="d-flex flex-column gap-3 py-2">
          {hasAnyRecipies ? (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="16" fontWeight="600" noWrap>
                Materiał jest użyty w
              </Typography>
              <Typography fontSize="16" fontWeight="700" noWrap>
                {materialRemovalPreview.numberOfRecipies}{" "}
                {pluralize.pl(materialRemovalPreview.numberOfRecipies, {
                  singular: "recepturze",
                  plural: "recepturach",
                  other: "recepturach",
                })}
              </Typography>
            </div>
          ) : (
            <Typography fontSize="16" fontWeight="600" noWrap>
              Materiał nie jest użyty w żadnej recepturze
            </Typography>
          )}
          {hasAnyRecipies ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-2">
                <Typography fontSize="14" fontWeight="600">
                  Czy odpiąć go od receptur i schematów?
                </Typography>
                <Radio
                  checked={filters.option === MaterialRemovalOption.DISABLE_IN_RECIPE}
                  label="Nie"
                  onClick={() => setFilter("option", MaterialRemovalOption.DISABLE_IN_RECIPE)}
                  size="sm"
                />
                <Radio
                  checked={filters.option === MaterialRemovalOption.UNPIN_FROM_RECIPE}
                  label="Tak"
                  onClick={() => setFilter("option", MaterialRemovalOption.UNPIN_FROM_RECIPE)}
                  size="sm"
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <Typography fontSize="14" fontWeight="600">
                  Czy zerować stan?
                </Typography>
                <Radio
                  checked={!filters.isResetMaterialStockRequired}
                  label="Nie"
                  onClick={() => setFilter("isResetMaterialStockRequired", false)}
                  size="sm"
                />
                <Radio
                  checked={filters.isResetMaterialStockRequired}
                  label="Tak"
                  onClick={() => setFilter("isResetMaterialStockRequired", true)}
                  size="sm"
                />
              </div>
            </div>
          ) : (
            <div>
              <Typography fontSize="14" fontWeight="600" noWrap>
                Czy na pewno chcesz usunąć materiał?
              </Typography>
              <Radio
                checked={!confirmRemoval}
                label="Nie"
                onClick={() => setConfirmRemoval(false)}
                size="sm"
              />
              <Radio
                checked={confirmRemoval}
                label="Tak"
                onClick={() => setConfirmRemoval(true)}
                size="sm"
              />
            </div>
          )}
        </div>
        <div className="d-flex align-items-center gap-2 py-3">
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Anuluj
          </Button>
          <Button
            className="text-uppercase"
            disabled={!hasAnyRecipies && !confirmRemoval}
            isLoading={confirmMaterialRemoval.isLoading}
            onClick={() =>
              confirmMaterialRemoval.mutate({
                materialId,
                search: searchParams,
              })
            }
            size="medium"
            variant="deepPurple"
          >
            Usuń materiał
          </Button>
        </div>
      </div>
    </Modal>
  );
};
