import { useUnloadingLineItemDetails } from "api/wms/hooks";
import {
  NormalizedUnloadingPackageGroup,
  PackageStockStatus,
  UnloadingDetails,
  statusDict,
} from "api/wms/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "pages/wms/shared/Wms.module.css";
import unloadingIcon from "assets/images/forkliftBlue.svg";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { useMemo } from "react";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useLineItemsDetailsColumns } from "./useLineItemsDetailsColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { UUID } from "api/types";
import { countryToFlagDict } from "constants/countriesFlags";
import { wmsUnloadingActions } from "api/wms/unloading/actions";

interface Props {
  close: () => void;
  lineItemId: UUID;
  unloading: UnloadingDetails;
}

export const unloadingPackageGroupStatusDict: Record<
  PackageStockStatus,
  { label: string; variant: "success" | "outline" }
> = {
  IN_STOCK: { label: "przyjęte na magazyn", variant: "success" },
  NOT_IN_STOCK: { label: "nie przyjęte", variant: "outline" },
};

export const LineItemsDetailsModal = ({ close, lineItemId, unloading }: Props) => {
  const { data: lineItem, error, isLoading, isFetching } = useUnloadingLineItemDetails(lineItemId);
  const columns = useLineItemsDetailsColumns();
  const handleDownloadUnloadingLineItemPdf = wmsUnloadingActions.useDownloadUnloadingLineItemPdf();
  const normalizedData: NormalizedUnloadingPackageGroup[] = useMemo(() => {
    if (!lineItem) return [];
    return lineItem.packagesGroups.map(packageGroup => {
      const subRows: NormalizedUnloadingPackageGroup[] = (packageGroup.packages || []).map(
        packageRow => {
          return {
            id: packageRow.id,
            name: packageRow.name,
            location: packageRow.location,
            stockStatus: packageRow.stockStatus,
            addedToStockAt: packageRow.addedToStockAt,
            collaborator: packageRow.collaborator,
          } as NormalizedUnloadingPackageGroup;
        },
      );
      return {
        id: packageGroup.id,
        name: packageGroup.name,
        location: null,
        stockStatus: null,
        addedToStockAt: null,
        collaborator: null,
        subRows,
      } as NormalizedUnloadingPackageGroup;
    });
  }, [lineItem]);

  if (isLoading) return null;

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={750}
        title={
          <Typography fontSize="20" fontWeight="700">
            Szczegóły zamówienia
          </Typography>
        }
      >
        <div className="mt-3 py-5">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(lineItem);

  return (
    <Modal
      close={close}
      isOpen
      width={750}
      title={
        <Typography fontSize="20" fontWeight="700">
          Szczegóły zamówienia {lineItem.signature}
        </Typography>
      }
    >
      <div className={styles.lineItemContainer}>
        <div className={styles.lineItemTitle}>
          <Typography fontSize="26" fontWeight="700">
            {lineItem.unloading.signature}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            <img alt="picking" src={unloadingIcon} style={{ height: 24, width: 24 }} />
            <Typography color="blue300" fontSize="20" fontWeight="700">
              Rozładunek
            </Typography>
          </div>
          {lineItem.unloading.ramp && (
            <Tag label={lineItem.unloading.ramp.name} variant="deepPurple50" />
          )}
        </div>
        <div className={styles.lineItemSection}>
          <div className={styles.lineItemHeader}>
            <div className={styles.lineItemHeaderGeneral}>
              <Typography fontSize="16" fontWeight="700">
                {lineItem.signature}
              </Typography>
              <Tag
                label={statusDict[lineItem.status].label}
                variant={statusDict[lineItem.status].variant}
              />
              {lineItem.address.countryCode && (
                <div className="d-flex align-items-center gap-1">
                  <img alt="kraj" src={countryToFlagDict[lineItem.address.countryCode]} />
                  <Typography fontSize="10" fontWeight="700">
                    {lineItem.address.countryCode}
                  </Typography>
                </div>
              )}
            </div>
            <div className={styles.lineItemHeaderBuyer}>
              <Typography fontSize="12" fontWeight="700">
                {lineItem.buyer.firstName || lineItem.buyer.lastName
                  ? `${lineItem.buyer.firstName} ${lineItem.buyer.lastName}`
                  : "---"}
              </Typography>
            </div>
            <IconButton
              icon={<MdiQrCode size="16" />}
              onClick={event => {
                event.stopPropagation();
                handleDownloadUnloadingLineItemPdf(
                  lineItem.id,
                  lineItem.unloading.id,
                  lineItem.signature,
                );
              }}
              variant="transparent"
            />
          </div>
          <div className={styles.tableContainer}>
            <Table<NormalizedUnloadingPackageGroup>
              rows={normalizedData}
              columns={columns}
              isLoading={isLoading || isFetching}
              error={error}
              uiSchema={comfortableListUiSchema}
            />
          </div>
        </div>
        <div>
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
