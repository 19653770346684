import { TradingDocument } from "api/trading-documents/models";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import styles from "../../tradingDocumentsList/TradingDocumentsList.module.css";
import cx from "classnames";
import { correctionInvoiceListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { StateLabel } from "components/common/stateLabel";
import { dateFns, pluralize } from "utilities";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "pages/tradingDocuments/shared/utils/isDateOlderThan24Hours";
import { dateExceedsToday } from "pages/tradingDocuments/shared/utils/dateExceedsToday";
import { MoreDataCounter } from "components/common/moreDataCounter";
import React from "react";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  correctionInvoice: TradingDocument;
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
}

export const ListItem = ({
  correctionInvoice,
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  selectMutlipleItems,
  togglePanel,
}: Props) => {
  return (
    <ModuleRow
      className={cx({
        active: isActive(correctionInvoice.id),
        highlighted:
          isHighlighted(correctionInvoice.id) ||
          highlightedRows.some(row => row.id === correctionInvoice.id),
      })}
      gridStyle={correctionInvoiceListConfig.grid}
      onMouseDown={event =>
        selectMutlipleItems(event, correctionInvoice.id, index, () =>
          togglePanel(correctionInvoice.id),
        )
      }
    >
      <div>
        <ConfirmCheckbox tradingDocument={correctionInvoice} />
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className={cx(styles.client, "fw-700")}>
          {correctionInvoice.recipientCompanyName ? (
            correctionInvoice.recipientCompanyName
          ) : (
            <>
              {correctionInvoice.recipientFirstName} {correctionInvoice.recipientLastName}
            </>
          )}
        </div>
        {!isDateOlderThan48Hours(correctionInvoice.createdAt) && (
          <StateLabel
            kind="green"
            opacity={
              correctionInvoice.createdAt && isDateOlderThan24Hours(correctionInvoice.createdAt)
                ? "high"
                : "default"
            }
          >
            <div>{tradingDocumentConstants.invoiceStatusDict.NEW}</div>
          </StateLabel>
        )}
      </div>
      <div className={styles.signature}>{correctionInvoice.signature}</div>
      <div className={cx(styles.signature, "d-flex align-items-center")}>
        {correctionInvoice.orders.slice(0, 1).map(order => {
          return order ? order.signature : "";
        })}{" "}
        &nbsp;
        {correctionInvoice.orders.filter(order => order).length - 1 > 0 ? (
          <MoreDataCounter
            className={styles.moreDataCounterOverride}
            counter={correctionInvoice.orders.filter(order => order).length - 1}
            style={{ width: "max-content" }}
          />
        ) : null}
      </div>
      <div className={styles.reason}>
        {Boolean(correctionInvoice.reasons.length)
          ? correctionInvoice.reasons.map((reason, index) => (
              <React.Fragment key={index}>
                {correctionInvoice.reasons.length > index + 1 ? (
                  <>
                    {tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action]}
                    ,&nbsp;
                  </>
                ) : (
                  <>{tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action]}</>
                )}
              </React.Fragment>
            ))
          : "--"}
      </div>
      <div className={styles.signature}>{correctionInvoice.masterDocument?.signature}</div>
      <div className={cx(styles.date, "text-color-grey")}>
        {correctionInvoice.invoiceIssueDate ? (
          <div
            className={cx({
              "text-red-6": dateExceedsToday(correctionInvoice.invoiceIssueDate),
            })}
          >
            {dateFns.format(new Date(correctionInvoice.invoiceIssueDate), "dd.MM.yyyy")}
          </div>
        ) : (
          "---"
        )}
      </div>
      <div className={cx(styles.date, "d-flex align-items-center")}>
        {correctionInvoice.paymentDeadline ? (
          <div
            className={cx({
              "text-red-6": dateExceedsToday(correctionInvoice.paymentDeadline),
            })}
          >
            {dateFns.format(new Date(correctionInvoice.paymentDeadline), "dd.MM.yyyy")}
          </div>
        ) : (
          "--"
        )}
        {correctionInvoice.basePaymentDeadlineDelta && (
          <div>
            &nbsp;&nbsp;({correctionInvoice.basePaymentDeadlineDelta}{" "}
            {pluralize.pl(correctionInvoice.basePaymentDeadlineDelta, {
              singular: "dzień",
              plural: "dni",
              other: "dni",
            })}
            )
          </div>
        )}
      </div>
      <div>
        {correctionInvoice.signatureOfAnAuthorizedPerson ? (
          <div className={styles.assignee}>{correctionInvoice.signatureOfAnAuthorizedPerson}</div>
        ) : (
          "--"
        )}
      </div>
      <div className="fw-700">
        {correctionInvoice.latestMessageStatus &&
        correctionInvoice.latestMessageStatus.length > 0 ? (
          <>
            {
              tradingDocumentConstants.tradingDocumentNotificationStatusDict[
                correctionInvoice.latestMessageStatus
              ]
            }
          </>
        ) : (
          "--"
        )}
      </div>
      <div>
        {correctionInvoice.assignedTo && (
          <div className="d-flex align-items-center gap-1">
            {correctionInvoice.assignedTo.avatar ? (
              <div className={cx(styles.listAvatar, styles.avatarSmall)}>
                <img alt="avatar" src={correctionInvoice.assignedTo.avatar} />
              </div>
            ) : (
              <InitialsAvatar
                className={cx(styles.listAvatar, styles.avatarSmall)}
                firstName={correctionInvoice.assignedTo.firstName}
                lastName={correctionInvoice.assignedTo.lastName}
                style={{ margin: 0 }}
              />
            )}
            <div className={styles.assignee}>
              {correctionInvoice.assignedTo.firstName} {correctionInvoice.assignedTo.lastName}
            </div>
          </div>
        )}
      </div>
      <div className={styles.subject}>
        {correctionInvoice.sellerCompanyName ? (
          correctionInvoice.sellerCompanyName
        ) : (
          <>
            {correctionInvoice.sellerFirstName} {correctionInvoice.sellerLastName}
          </>
        )}
      </div>
    </ModuleRow>
  );
};
