import { materialsActions } from "api/manufacturing/materials/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiCached } from "components/miloDesignSystem/atoms/icons/MdiCached";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal, useToggle } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { ManualMaterialStockUpdate } from "../../manualMaterialStockUpdate/ManualMaterialStockUpdate";
import { OrderMaterialModal } from "../../DemandAndAvailabilityList";
import { OrderMaterial } from "../../orderMaterial/OrderMaterialModal";
import {
  MaterialAvailabilityStatus,
  MaterialListItem,
  MaterialRemovalPreview,
} from "api/manufacturing/materials/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { ConfirmMaterialRemoval } from "../confirmMaterialRemoval/ConfirmMaterialRemoval";

export const PanelHeader = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { close } = useDrawer("materialsDemandAndAvailability");
  const { data: material } = materialsActions.useMaterial(panelId, {});
  const removeMaterialReplyModal = useStateModal<MaterialRemovalPreview>();
  const manualMaterialStockUpdateModal = useToggle();
  const orderMaterialModal: OrderMaterialModal = useStateModal();
  const { isTriggerElementHidden } = useScrollableContext();
  const removeMaterialMutation = materialsActions.useRemoveMaterial(removeMaterialReplyModal.open);

  if (!material) return null;

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="noWrap">
            <Typography fontSize="16" fontWeight="600" noWrap>
              {material.name}
            </Typography>
          </SlidingHeaderWrapper>
        ) : (
          <div />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          <div className="d-flex align-items-center gap-2">
            <Button
              className="text-uppercase"
              onClick={manualMaterialStockUpdateModal.open}
              size="small"
              startIcon={MdiCached}
              variant="gray"
            >
              ręcznie zmień stan
            </Button>
            <Button
              className="text-uppercase"
              disabled={material.stock.stockStatus === MaterialAvailabilityStatus.NA}
              size="small"
              variant="gray"
              onClick={() => {
                orderMaterialModal.open({ materials: [(material as unknown) as MaterialListItem] });
              }}
            >
              zamów
            </Button>
            <Menu
              disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
              menuItems={[
                {
                  type: MenuItemType.TEXT,
                  options: {
                    color: "red100",
                    iconStart: MdiDelete,
                  },
                  onClick: () => removeMaterialMutation.mutate(material.id),
                  text: "Usuń",
                },
              ]}
            />
          </div>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {orderMaterialModal.isOpen && <OrderMaterial orderMaterialModal={orderMaterialModal} />}
      {manualMaterialStockUpdateModal.isOpen && (
        <ManualMaterialStockUpdate
          close={manualMaterialStockUpdateModal.close}
          material={material}
        />
      )}
      {removeMaterialReplyModal.isOpen && (
        <ConfirmMaterialRemoval
          close={removeMaterialReplyModal.close}
          materialId={material.id}
          materialRemovalPreview={removeMaterialReplyModal.state}
        />
      )}
    </>
  );
};
