import { indexesActions } from "api/manufacturing/indexes/actions";
import { IndexListItem, IndexesTab } from "api/manufacturing/indexes/models";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { indexesUtils } from "utilities/indexes";
import { useIndexesColumns } from "./useIndexesColumns";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { indexesConstants } from "constants/indexes";
import { indexes as indexesNavigation } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/indexes";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanel } from "./rightPanel/RightPanel";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { IndexesTableToolbar } from "./indexesTableToolbar/IndexesTableToolbar";

export const IndexesList = ({ match }: RouteComponentProps<{ tab: IndexesTab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = indexesUtils.getIndexesListSearch({ query, tab });
  const { data: indexes, error, isLoading, isPreviousData, pagination } = indexesActions.useIndexes(
    search,
  );

  const indexesMultiSelect = useTableMultiSelect({ rows: indexes });

  const tableProps = useTableFeatureConnector({
    rows: indexes,
    withDrawer: "manufacturingIndexes",
    withMultiSelect: indexesMultiSelect,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });
  const columns = useIndexesColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: indexesConstants.getIndexesListTabs(),
          routesRoot: `manufacturing/${indexesNavigation.url}`,
          urlSpan: "list",
        }}
        viewLabel="MANUFACTURING_INDEXES"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<IndexListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
        <IndexesTableToolbar
          close={indexesMultiSelect.clear}
          indexes={indexesMultiSelect.selectedRows}
          numberOfSelectedItems={indexesMultiSelect.selectedRows.length}
        />
      </div>
    </PageWrapper>
  );
};
