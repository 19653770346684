import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelSection } from "components/utils/drawer";
import { getIsoDateFormat, handleDateField, pluralize } from "utilities";
import retailIcon from "assets/images/accountBox.svg";
import styles from "../RightPanel.module.css";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { useState } from "react";
import cx from "classnames";
import { CustomerSection } from "./CustomerSection";
import { UserAvatar } from "components/utils/userAvatar";
import ReactTooltip from "react-tooltip";
import { useToggle } from "hooks";
import { EditRecipientNamesModal } from "../../editRecipientNamesModal/EditRecipientNamesModal";
import { openEditRecipientNamesModal } from "./utils/openEditRecipientNamesModal";
import { AssignedUser } from "components/common/assignUser/AssignUser";
import { LinkToPage } from "components/utils/linkToPage";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiAccountCircle } from "components/miloDesignSystem/atoms/icons/MdiAccountCircle";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";

interface Props {
  tradingDocument: TradingDocument;
}

export const GeneralInfoSection = ({ tradingDocument }: Props) => {
  const [showAllOrders, setShowAllOrders] = useState(false);
  const editRecipientNamesModal = useToggle();
  const [editRecipientModalPosition, setEditRecipientModalPosition] = useState<{
    top: number;
    left: number;
  }>({
    top: 0,
    left: 0,
  });
  const changeAssignedUserMutation = usePatchTradingDocumentMutation();
  const isNotSalesAndNotProformaAndNotAdvance =
    tradingDocument.invoiceType !== "SALES" &&
    tradingDocument.invoiceType !== "PROFORMA" &&
    tradingDocument.invoiceType !== "ADVANCE";
  const isSalesOrProformaOrAdvance =
    tradingDocument.invoiceType === "SALES" ||
    tradingDocument.invoiceType === "PROFORMA" ||
    tradingDocument.invoiceType === "ADVANCE";
  const isCorrection = tradingDocument.invoiceType === "CORRECTION";

  return (
    <RightPanelSection>
      <div className="d-flex align-items-start justify-content-between">
        <div>
          <InfoLabel title="przypisano do">
            <AssignedUser
              value={tradingDocument?.assignedTo}
              onChange={value =>
                changeAssignedUserMutation.mutate({
                  assignedTo: value,
                  id: tradingDocument.id,
                })
              }
            />
          </InfoLabel>
          <InfoLabel title="XML pobrano">
            {tradingDocument.xmlOptimaDownloadBy?.firstName ? (
              <>
                {tradingDocument.xmlOptimaDownloadBy.avatar ? (
                  <UserAvatar
                    className="mr-1"
                    avatarSrc={tradingDocument.xmlOptimaDownloadBy.avatar}
                  />
                ) : null}
                &nbsp;
                {tradingDocument.xmlOptimaDownloadBy.firstName}&nbsp;
                {tradingDocument.xmlOptimaDownloadBy.lastName} &nbsp; &nbsp;
              </>
            ) : null}
            {handleDateField(tradingDocument.xmlOptimaDownloadAt)}
          </InfoLabel>
          <InfoLabel title="data wystawienia">
            {isNotSalesAndNotProformaAndNotAdvance && !isCorrection ? (
              <Typography fontSize="14" fontWeight="500">
                {handleDateField(tradingDocument.invoiceIssueDate)}
              </Typography>
            ) : (
              <DatePicker.Async
                isNullable
                mutationHook={usePatchTradingDocumentMutation}
                transformQueryData={date => ({
                  id: tradingDocument.id,
                  invoiceIssueDate: date ? getIsoDateFormat(date) : null,
                  pitTaxObligationDate: date ? getIsoDateFormat(date) : null,
                  vatTaxObligationDate: date ? getIsoDateFormat(date) : null,
                })}
                value={tradingDocument.invoiceIssueDate}
              />
            )}
          </InfoLabel>
          <InfoLabel title="data sprzedaży">
            {isNotSalesAndNotProformaAndNotAdvance && !isCorrection ? (
              <Typography fontSize="14" fontWeight="700">
                {handleDateField(tradingDocument.invoiceDeliveryDate)}
              </Typography>
            ) : (
              <DatePicker.Async
                isNullable
                mutationHook={usePatchTradingDocumentMutation}
                transformQueryData={date => ({
                  id: tradingDocument.id,
                  invoiceDeliveryDate: date ? getIsoDateFormat(date) : null,
                  pitTaxObligationDate: date ? getIsoDateFormat(date) : null,
                  vatTaxObligationDate: date ? getIsoDateFormat(date) : null,
                })}
                value={tradingDocument.invoiceDeliveryDate}
              />
            )}
          </InfoLabel>
          <InfoLabel title="data utworzenia">
            <Typography fontSize="14" fontWeight="700">
              {handleDateField(tradingDocument.createdAt)}
            </Typography>
          </InfoLabel>
          <InfoLabel title="data płatności">
            <Typography fontSize="14" fontWeight="700">
              {handleDateField(tradingDocument.invoicePaymentDate)}
            </Typography>
          </InfoLabel>
          <InfoLabel title="sprzedawca">
            <StateLabel kind="orangeDark">
              <Typography fontSize="10" fontWeight="600">
                {Boolean(tradingDocument.sellerCompanyName)
                  ? `${tradingDocument.sellerCompanyName}`
                  : `${tradingDocument.sellerFirstName} ${tradingDocument.sellerLastName}`}
              </Typography>
            </StateLabel>
            <div className="d-flex align-items-center gap-1">
              <Typography color="grey400" fontSize="14" fontWeight="600">
                NIP:
              </Typography>
              <Typography fontSize="14" fontWeight="600">
                {Boolean(tradingDocument.seller?.taxId)
                  ? `${tradingDocument.seller?.taxId}`
                  : EMPTY_VALUE}
              </Typography>
            </div>
          </InfoLabel>
          {isSalesOrProformaOrAdvance &&
          (tradingDocument.kind === "DROPSHIPPING" || tradingDocument.kind === "WHOLESALE") ? (
            <CustomerSection
              editRecipientModalPosition={editRecipientModalPosition}
              salesInvoice={tradingDocument}
              setEditRecipientModalPosition={setEditRecipientModalPosition}
            />
          ) : isSalesOrProformaOrAdvance && tradingDocument.kind === "OWN_ENTITY" ? (
            <InfoLabel title="nabywca">
              <div className="d-flex align-items-center gap-2">
                <Tooltip title="Podmiot własny">
                  <div
                    className={cx(styles.filterChip, styles.filterOwnEntity)}
                    onClick={e =>
                      openEditRecipientNamesModal(
                        e,
                        setEditRecipientModalPosition,
                        editRecipientNamesModal.toggle,
                      )
                    }
                  >
                    <MdiAccountCircle size="16" />
                    <Typography fontSize="12" fontWeight="700">
                      {tradingDocument.recipientCompanyName ??
                        `${tradingDocument.recipientFirstName} ${tradingDocument.recipientLastName}` ??
                        EMPTY_VALUE}
                    </Typography>
                  </div>
                </Tooltip>
                <div className="d-flex align-items-center gap-1">
                  <Typography color="grey400" fontSize="14" fontWeight="600">
                    NIP:
                  </Typography>
                  <Typography fontSize="14" fontWeight="600">
                    {tradingDocument.recipientTaxId || EMPTY_VALUE}
                  </Typography>
                </div>
                {editRecipientNamesModal.isOpen && (
                  <EditRecipientNamesModal
                    close={editRecipientNamesModal.close}
                    modalPosition={editRecipientModalPosition}
                    tradingDocument={tradingDocument}
                  />
                )}
              </div>
            </InfoLabel>
          ) : (
            <InfoLabel title="nabywca">
              <div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className={cx(styles.filterChip, styles.filterRetail)}
                    data-tip
                    data-for={`retail-${tradingDocument.id}`}
                    onClick={e =>
                      openEditRecipientNamesModal(
                        e,
                        setEditRecipientModalPosition,
                        editRecipientNamesModal.toggle,
                      )
                    }
                  >
                    <img alt="Ikona nabywcy" src={retailIcon} />
                    <div>
                      {tradingDocument.buyerCompanyName ? (
                        <div className="d-flex align-items-center gap-1">
                          {tradingDocument.buyerCompanyName}
                        </div>
                      ) : tradingDocument.buyerFirstName.length > 0 ||
                        tradingDocument.buyerLastName.length > 0 ? (
                        <div className="d-flex align-items-center gap-1">
                          {tradingDocument.buyerFirstName} &nbsp;
                          {tradingDocument.buyerLastName}
                        </div>
                      ) : (
                        <EmptyValue />
                      )}
                    </div>
                  </div>
                  {isSalesOrProformaOrAdvance && tradingDocument.canBeFiscalized ? (
                    <Typography color="grey400" fontSize="14" fontWeight="600">
                      Potrzebny paragon
                    </Typography>
                  ) : (
                    <div className="d-flex align-items-center gap-1">
                      <Typography color="grey400" fontSize="14" fontWeight="600">
                        NIP:
                      </Typography>
                      <Typography fontSize="14" fontWeight="600">
                        {tradingDocument.buyerTaxId || EMPTY_VALUE}
                      </Typography>
                    </div>
                  )}
                </div>
                <ReactTooltip
                  className={styles.customTooltip}
                  id={`retail-${tradingDocument.id}`}
                  place="top"
                  effect="solid"
                  arrowColor="transparent"
                  offset={{ top: -10 }}
                >
                  Klient detaliczny
                </ReactTooltip>
                {editRecipientNamesModal.isOpen && (
                  <EditRecipientNamesModal
                    close={editRecipientNamesModal.close}
                    modalPosition={editRecipientModalPosition}
                    tradingDocument={tradingDocument}
                  />
                )}
              </div>
            </InfoLabel>
          )}
          <InfoLabel title="termin płatności">
            <div className="d-flex gap-2">
              {tradingDocument.invoiceType !== "SALES" && !isCorrection ? (
                <Typography fontSize="16" fontWeight="700">
                  {handleDateField(tradingDocument.paymentDeadline)}
                </Typography>
              ) : (
                <DatePicker.Async
                  isNullable
                  mutationHook={usePatchTradingDocumentMutation}
                  transformQueryData={date => ({
                    id: tradingDocument.id,
                    paymentDeadline: date ? getIsoDateFormat(date) : null,
                  })}
                  value={tradingDocument.paymentDeadline}
                />
              )}
              {tradingDocument.basePaymentDeadlineDelta && (
                <div className="d-flex align-items-center">
                  <StateLabel kind="cyan">
                    <span>
                      {tradingDocument.basePaymentDeadlineDelta}{" "}
                      {pluralize.pl(tradingDocument.basePaymentDeadlineDelta, {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      })}
                    </span>
                  </StateLabel>
                </div>
              )}
            </div>
          </InfoLabel>
          <InfoLabel title="zamówienia">
            {Boolean(tradingDocument.orders?.length) &&
              !showAllOrders &&
              tradingDocument.orders.slice(0, 2).map((order, index) => {
                if (order !== null) {
                  return (
                    <LinkToPage
                      content={
                        <>
                          <div className="fs-14 fw-500">{order.signature}</div>
                          {index + 1 !== tradingDocument.orders.length &&
                            tradingDocument.orders[index + 1] !== null &&
                            index < 1 && <div>,</div>}
                        </>
                      }
                      key={order.id}
                      url={getOrderRedirectUrl(order)}
                    />
                  );
                }
                return null;
              })}
            {Boolean(tradingDocument.orders?.length) && showAllOrders && (
              <div className={cx(styles.showAllOrders)}>
                <div className="d-flex align-items-center flex-wrap">
                  {tradingDocument.orders.map((order, index) => {
                    if (order !== null) {
                      return (
                        <LinkToPage
                          content={
                            <>
                              <div className="fs-14 fw-500">{order.signature}</div>
                              {index + 1 !== tradingDocument.orders.length &&
                                tradingDocument.orders[index + 1] !== null && <div>,&nbsp;</div>}
                            </>
                          }
                          url={getOrderRedirectUrl(order)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
                <Button
                  className="mt-2 text-uppercase"
                  onClick={() => setShowAllOrders(prev => !prev)}
                  size="small"
                  variant="gray"
                >
                  Ukryj
                </Button>
              </div>
            )}
            {tradingDocument.orders.filter(order => order).length - 2 > 0 && !showAllOrders && (
              <div onClick={() => setShowAllOrders(prev => !prev)}>
                <MoreDataCounter
                  className={styles.moreDataCounterOverride}
                  counter={tradingDocument.orders.filter(order => order).length - 2}
                />
              </div>
            )}
          </InfoLabel>
          {tradingDocument.invoiceType === "SALES" && (
            <InfoLabel title="proforma">
              {tradingDocument.proforma ? (
                <LinkToPage
                  content={
                    <Typography color="inherit" fontSize="14" fontWeight="600">
                      {tradingDocument.proforma.signature}
                    </Typography>
                  }
                  url={`/finances/proformas/list/all?panelId=${tradingDocument.proforma.id}`}
                />
              ) : (
                <EmptyValue />
              )}
            </InfoLabel>
          )}
        </div>
      </div>
    </RightPanelSection>
  );
};
