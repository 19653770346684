import { ManufacturingEmployeeDetails } from "api/manufacturing/employees/models";
import { walletsActions } from "api/manufacturing/wallet/actions";
import { RightPanelSection } from "components/utils/drawer";
import { queryString } from "utilities";
import { useTransactionsColumns } from "./useTransactionsColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { WalletEmployeeTransaction } from "api/manufacturing/wallet/models";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { EmptySection } from "components/common/emptySection/EmptySection";

interface Props {
  employee: ManufacturingEmployeeDetails;
}

export const TransactionsSection = ({ employee }: Props) => {
  const search = queryString.stringify({
    employeeId: employee.id,
    pageSize: 3,
  });
  const {
    data: employeeTransactions,
    error,
    isLoading,
    isPreviousData,
  } = walletsActions.useEmployeeTransactions(search);

  const tableProps = useTableFeatureConnector({ rows: employeeTransactions });

  const columns = useTransactionsColumns();

  return (
    <RightPanelSection title="Płatności">
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<WalletEmployeeTransaction>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={comfortableListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              hideHeader: !employeeTransactions?.length,
              noResultComponent: <EmptySection label="Brak ostatnich płatności" />,
              ...tableProps.overrides?.(),
            };
          }}
        />
      </div>
    </RightPanelSection>
  );
};
