import { manufacturersActions } from "api/manufacturers/actions";
import { manufacturingActions } from "api/manufacturing/actions";
import { RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { useLastEmailsColumns } from "./useLastEmailsColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { ManufacturingEmail } from "api/manufacturing/models";
import { EmptySection } from "components/common/emptySection/EmptySection";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { useNavigate } from "hooks/useNavigate";

export const EmailSection = () => {
  const { query } = useQuery();
  const { data: manufacturer } = manufacturersActions.useManufacturer(Number(query.panelId), {
    enabled: Boolean(query.panelId),
  });
  const search = queryString.stringify({
    manufacturer: manufacturer!.id,
    pageSize: 4,
  });
  const {
    data: emails,
    error,
    isLoading,
    isPreviousData,
  } = manufacturingActions.useManufacturerOrderEmails(search);
  const navigate = useNavigate();

  const columns = useLastEmailsColumns();
  const tableProps = useTableFeatureConnector({
    rows: emails,
  });

  return (
    <RightPanelSection
      title={
        <div className="w-100 d-flex align-items-center justify-content-between">
          <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
            Wysyłka zamówień
          </Typography>
          <Button
            className="text-uppercase"
            endIcon={MdiArrowForward}
            onClick={() => navigate(`/manufacturing/manufacturers/${manufacturer!.id}/emails/all`)}
            size="medium"
            variant="gray"
          >
            Zobacz wcześniejsze
          </Button>
        </div>
      }
    >
      <Table<ManufacturingEmail>
        columns={columns}
        error={error}
        isLoading={isLoading || isPreviousData}
        uiSchema={comfortableListUiSchema}
        {...tableProps}
        overrides={() => ({
          ...tableProps?.overrides?.(),
          hideHeader: !emails.length,
          noResultComponent: (
            <div className="mt-2">
              <EmptySection label="Brak maili" />
            </div>
          ),
        })}
      />
    </RightPanelSection>
  );
};
