import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import styles from "./ManufacturingTicket.module.css";
import { ManufacturingTicketProps } from "./types";
import { cx } from "utilities";
import { useQuery } from "hooks";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Avatar } from "components/miloDesignSystem/atoms/avatar/Avatar";
import { MdiStacks } from "components/miloDesignSystem/atoms/icons/MdiStacks";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { ImplementedBy, ManufacturingUnitGroupPriority } from "api/manufacturing/units/models";
import { isAfter, isBefore, subMinutes } from "date-fns";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiQualityAlert } from "components/miloDesignSystem/atoms/icons/MdiQualityAlert";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { ClickOutsideHandler } from "components/utils";

export const ManufacturingTicket = ({
  assignEmployee,
  attributes,
  downloadLabelFn,
  isColumnActive,
  isDragged,
  isLatest,
  onClick,
  ticket,
}: ManufacturingTicketProps) => {
  const { query } = useQuery();
  const isPrioritized = ticket.priority && manufacturingStagesUtils.isUnitUrgent(ticket.priority);
  const isCritical = ticket.priority && manufacturingStagesUtils.isUnitCritical(ticket.priority);
  const isCancelled = ticket.isCancelled && ticket.elementsCount === undefined;
  const isRecentlyMoved = (() => {
    if (!ticket.recentlyMovedDate || !isLatest?.(ticket)) return false;
    const now = new Date();
    const oneMinuteAgo = subMinutes(now, 1);

    return (
      isAfter(new Date(ticket.recentlyMovedDate), oneMinuteAgo) &&
      isBefore(new Date(ticket.recentlyMovedDate), now)
    );
  })();

  return (
    <StyledManufacturingTicket
      className={cx("container col-12", {
        dashedTableRow: isCancelled,
      })}
      isActive={query.unitPanelId === ticket.id && isColumnActive}
      isCritical={isCritical}
      isDragged={isDragged}
      isRecentlyMoved={isRecentlyMoved}
      onClick={() => onClick(ticket)}
    >
      <div className={cx(styles.ticketHeader, "cut-text")}>
        <div className="d-flex align-items-center gap-2">
          {isPrioritized && (
            <div className="d-flex align-items-center gap-2">
              <TicketMarker priority={ticket.priority!} />
              <TicketIcon priority={ticket.priority!}>
                {ticket.priority
                  ? manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[ticket.priority]
                      .light.icon
                  : null}
              </TicketIcon>
            </div>
          )}
          {ticket.signature && (
            <Typography
              className="flex-shrink-1"
              color={
                ticket.priority
                  ? manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[ticket.priority]
                      .light.color
                  : "neutralBlack48"
              }
              fontSize="14"
              fontWeight="600"
              noWrap
            >
              {ticket.signature}
            </Typography>
          )}
          {ticket.implementedBy === ImplementedBy.CONTRACTOR && ticket.manufacturer && (
            <Tag label={ticket.manufacturer} variant="success" />
          )}
          {ticket.elementsCount !== undefined && (
            <div className="d-flex flex-shrink-0 align-items-center gap-1">
              <MdiStacks size="14" />
              <Typography fontSize="14" fontWeight="600">
                {Object.values(ticket.elementsCount).reduce((sum, value) => sum + value, 0)}
              </Typography>
            </div>
          )}
          {ticket.elementsCount !== undefined && Boolean(ticket.elementsCount.A) && (
            <div className="d-flex flex-shrink-0 align-items-center gap-1">
              <TicketIcon priority={ManufacturingUnitGroupPriority.A}>
                {
                  manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                    ManufacturingUnitGroupPriority.A
                  ].light.icon
                }
              </TicketIcon>
              <Typography fontSize="14" fontWeight="600">
                x{ticket.elementsCount.A}
              </Typography>
            </div>
          )}
          {ticket.elementsCount !== undefined && Boolean(ticket.elementsCount.B) && (
            <div className="d-flex flex-shrink-0 align-items-center gap-1">
              <TicketIcon priority={ManufacturingUnitGroupPriority.B}>
                {
                  manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                    ManufacturingUnitGroupPriority.B
                  ].light.icon
                }
              </TicketIcon>
              <Typography fontSize="14" fontWeight="600">
                x{ticket.elementsCount.B}
              </Typography>
            </div>
          )}
          <Typography color="neutralBlack48" fontSize="14" fontWeight="700" noWrap>
            {ticket.group}
          </Typography>
        </div>
        <div className="d-flex flex-1 justify-content-end align-items-center gap-2">
          {isCancelled && <Tag label="rezygnacja" variant="warning" />}
          {ticket.isDeclined && (
            <Tag
              label="zgłoszono uwagi"
              startIcon={<MdiQualityAlert color="warning600" size="14" />}
              variant="warning"
            />
          )}
          {ticket.implementedBy !== ImplementedBy.CONTRACTOR && ticket.elementsCount === undefined && (
            <Popover
              aria-label={ticket.id}
              content={({ hide }) => (
                <ClickOutsideHandler
                  onClickOutside={hide}
                  outsideClickIgnoreClass={ignoreClickOutsideClassName}
                >
                  <div onClick={event => event.stopPropagation()}>
                    {assignEmployee && assignEmployee(ticket, hide)}
                  </div>
                </ClickOutsideHandler>
              )}
              hideOnClickOutside={false}
              variant="DARK"
            >
              <div
                className="d-flex align-items-center gap-1"
                onClick={event => event.stopPropagation()}
              >
                <Avatar size="sm" user={ticket.employee || null} theme="light" />
              </div>
            </Popover>
          )}
          <FileDownloadHandler factoryFn={() => downloadLabelFn(ticket)} type="pdf">
            {({ download, isLoading }) => (
              <IconButton
                icon={MdiQrCode}
                isLoading={isLoading}
                onClick={event => {
                  event.stopPropagation();
                  download();
                }}
                variant="transparent"
              />
            )}
          </FileDownloadHandler>
        </div>
      </div>
      <div className={cx(styles.ticketAttributes, "col-12 pl-0 pr-0 cut-text")}>
        {attributes(ticket)}
      </div>
    </StyledManufacturingTicket>
  );
};

const StyledManufacturingTicket = styled.div<{
  isActive: boolean;
  isCritical?: boolean;
  isDragged: boolean;
  isRecentlyMoved: boolean;
}>`
  background: var(--neutralWhite100);
  border: 1px solid var(--neutralWhite100);
  border-radius: 4px;
  box-shadow: 0px 2px 4px -2px #0000001F;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 50px;
  padding: 4px 8px;
  &:hover {
    background: var(--grey25);
    border: 1px solid var(--neutralBlack20);
  }
  ${props =>
    props.isCritical &&
    css({
      border: "1px solid var(--danger500)",
    })}
  ${props =>
    props.isActive &&
    css({
      background: "var(--deepPurple75)",
      border: "1px solid var(--neutralBlack20)",
    })}
  ${props =>
    props.isDragged &&
    css({
      border: "1px solid var(--deepPurple400)",
      boxShadow: "0px 32px 32px -12px #0000001F",
    })}
  ${props =>
    props.isRecentlyMoved &&
    css({
      background: "var(--blue50)",
      border: props.isCritical ? "1px solid var(--danger500)" : "1px solid var(--blue50)",
      ":hover": {
        background: "var(--blue50)",
      },
    })}
`;

const TicketIcon = styled.div<{
  priority: ManufacturingUnitGroupPriority;
}>`
  border-radius: 20px;
  border: 1px solid;
  padding: 1px;
  ${props =>
    props.priority &&
    css({
      borderColor:
        colorPalette[
          manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[props.priority].light
            .color
        ],
    })}
`;

const TicketMarker = styled.div<{
  priority: ManufacturingUnitGroupPriority;
}>`
  border-radius: 4px;
  height: 20px;
  width: 4px;
  ${props =>
    props.priority &&
    css({
      background:
        colorPalette[
          manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[props.priority].light
            .color
        ],
    })}
`;

const ignoreClickOutsideClassName = "manufacturing-ticket-assign-employee-ignore-class-name";
