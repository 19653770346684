import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { ImplementedBy, ManufacturingUnitListViewDetails } from "api/manufacturing/units/models";
import { UserWithInitials } from "api/users/models";
import { InfoLabel } from "components/common/infoLabel";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { RightPanelSection } from "components/utils/drawer";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { useSelector } from "hooks";
import { dictToList, getStandardDateFormat } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";

interface Props {
  unitDetails: ManufacturingUnitListViewDetails;
}

export const ImplementationSection = ({ unitDetails }: Props) => {
  const implementedByOptions = dictToList(manufacturingStagesConstants.implementedByOptions).map(
    ({ key, value }) => ({
      text: value.label,
      type: MenuItemType.TEXT,
      value: key,
    }),
  );
  const employees = useSelector(store => store.partials.employees);

  const employeesOptions = employees.map(employee => ({
    icon: employee.avatar,
    text: `${employee.firstName} ${employee.lastName}`,
    type: MenuItemType.ICON,
    value: employee.id,
  }));

  const getEmployeeUser = (id: number): UserWithInitials => {
    const searchedEmployee = employees.find(employee => employee.id === id);
    assertIsDefined(searchedEmployee);
    return {
      avatar: searchedEmployee.avatar,
      firstName: searchedEmployee.firstName,
      id: searchedEmployee.id,
      initialsBackgroundColor: searchedEmployee.initialsBackgroundColor,
      initialsTextColor: searchedEmployee.initialsTextColor as ColorPalette,
      lastName: searchedEmployee.lastName,
    };
  };

  return (
    <RightPanelSection title="Realizacja">
      <div className="pb-2">
        <InfoLabel title="realizacja">
          <Select.Async
            items={implementedByOptions}
            mutationHook={manufacturingUnitsActions.useListUnitPatch}
            transformQueryData={implementedBy => ({
              id: unitDetails.id,
              toUpdate: {
                implementedBy: implementedBy as ImplementedBy,
              },
            })}
            selected={unitDetails.implementedBy}
          />
        </InfoLabel>
      </div>
      <div className="pb-2">
        <InfoLabel title="pracownik">
          <Select.Async
            items={employeesOptions}
            mutationHook={manufacturingUnitsActions.useListUnitPatch}
            transformQueryData={employee => ({
              id: unitDetails.id,
              toUpdate: {
                employee: employee ? getEmployeeUser(employee as number) : null,
              },
            })}
            selected={unitDetails.employee?.id ?? null}
          />
        </InfoLabel>
      </div>
      <div className="pb-2">
        <InfoLabel title="zlecono">
          <Typography fontSize="14" fontWeight="400">
            {getStandardDateFormat(unitDetails.createdAt)}
          </Typography>
        </InfoLabel>
      </div>
    </RightPanelSection>
  );
};
