import { routeActions } from "api/routes/actions";
import { PageHeader } from "components/common";
import { useNavigate } from "hooks/useNavigate";
import { useParams } from "react-router";
import { dateFns } from "utilities";
import styles from "../RouteProgress.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowBack } from "components/miloDesignSystem/atoms/icons/MdiArrowBack";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

export const ListHeader = ({ clearSelected }: { clearSelected: () => void }) => {
  const { route } = useParams<{ route: string }>();
  const { data, isLoading } = routeActions.useManufacturingRoute(Number(route));

  return (
    <div>
      <PageHeader
        title={
          <div className="d-flex align-items-center">
            <Typography fontSize="20" fontWeight="700" className="mr-2">
              Postęp produkcji na trasie:
            </Typography>
            {isLoading ? <Spinner size={20} /> : data?.signature}
          </div>
        }
        viewLabel="LOGISTICS_ROUTES"
        bottomBarButtons={
          <>
            <div className="d-flex gap-1">
              <Typography fontSize="12" fontWeight="400">
                data załadunku:
              </Typography>
              <Typography fontSize="12" fontWeight="700" className={styles.text}>
                {data?.loadingDate
                  ? dateFns.format(new Date(data?.loadingDate), "dd.MM.yyyy")
                  : EMPTY_VALUE}
              </Typography>
            </div>
            <div className="d-flex gap-1">
              <Typography fontSize="12" fontWeight="400">
                data wyjazdu:
              </Typography>
              <Typography fontSize="12" fontWeight="700" className={styles.text}>
                {data?.departureDate
                  ? dateFns.format(new Date(data?.departureDate), "dd.MM.yyyy")
                  : EMPTY_VALUE}
              </Typography>
            </div>
            <RouteNavigation clearSelected={clearSelected} />
          </>
        }
      />
    </div>
  );
};

const RouteNavigation = ({ clearSelected }: { clearSelected: () => void }) => {
  const { route } = useParams<{ route: string }>();
  const { data } = routeActions.useManufacturingRoutesPosition(Number(route));

  const navigate = useNavigate();

  const navigateToRoute = (routeId: number) => {
    clearSelected();
    return navigate(`/manufacturing/routes/progress/${routeId}`);
  };

  return (
    <div className="d-flex align-items-center gap-2">
      <Button
        size="small"
        variant="gray"
        startIcon={MdiArrowBack}
        className="uppercase"
        disabled={!data?.next}
        onClick={() => navigateToRoute(data?.next!)}
      >
        poprzednia
      </Button>
      <Button
        size="small"
        variant="gray"
        endIcon={MdiArrowForward}
        className="uppercase"
        disabled={!data?.previous}
        onClick={() => navigateToRoute(data?.previous!)}
      >
        następna
      </Button>
    </div>
  );
};
